import React, { useState } from 'react';
import USBTable from '@usb-inner-src/react-table';


const defaultRow = [
  {
    first: '-',
    second: <div></div>,
    third: <div></div>,
    fourth: <div></div>,
    fifth: <div></div>,
    sixth: <div></div>,
    seventh: <div></div>,
    eight: <div></div>,
    nineth: <div></div>,
  },
];

export default function UsbTableData({ data, columns }) {

  const [rangeArray, setRangeArray] = useState(['5', '10', '15', '30']);
  const [pageCount, setPageCount] = useState(25);
  const updateRangeArray = (pageCountTmp) => {
    let dropdownSpans 
    setTimeout(() => {
      dropdownSpans = document.getElementsByClassName('dropdown__btn-selection selected');
    }, 500);
    if(dropdownSpans && data && data.length>5){
      dropdownSpans[0].childNodes[0].data = dropdownSpans[1].childNodes[0].data = pageCountTmp
      setPageCount(pageCountTmp)
    }
  };
  const tableData =
    data?.map((each) => {
      return columns.reduce((acc, current) => {
        return { ...acc, [current.fieldFor]: current.element ? current.element(each) : each[current.key] || '' };
      }, {});
    }) || defaultRow;
  return (
    <>
    <div className='USBTable'>
      <USBTable
        columnData={columns || {}}
        paginationOptions={rangeArray}
        handleItemCountChange={(itemCount) =>
          updateRangeArray(itemCount)
        }
        addClasses="pagination-container-dashboard"
                    displayVariant="auto"
                    useBorder="true"
        paginationLabels={{
          previousButton: 'Previous page',
          nextButton: 'Next page',
          unit: 'Page',
          preposition: 'of',
          rowDropdown: '',
        }}
        tableData={tableData}
        displayVariant="auto"
      />
      </div>
    </>
  );
}