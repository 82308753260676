import React, { useState, useEffect, useContext } from 'react';
import { USBGrid, USBColumn, USBSelect } from '@usb-shield/components-react';
import USBButton from '@usb-inner-src/react-button';
import UsbTableData from '../../../components/utilities/UsbTable';
import { endpoints } from '../../../../config/connections';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Loader from '../../../components/utilities/Loader';
import '@usb-shield/react-forms-base/dist/library/styles/index.css';
import './psa-report.css';
import useUserRole from '../../../components/Hooks/use-UserRole';
import _noaccess from '../../../pages/_noaccess';
import callApi from '../../../components/utilities/callApi';
import "cross-fetch/polyfill";

const PSA = () => {
  const [fullData, setFullData] = useState([]);
  const [parentCompanyNameList, setParentCompanyNameList] = useState([]);
  const [legalEntityNamesList, setLegalEntityNamesList] = useState([]);
  const [dealNameList, setDealNameList] = useState([]);
  const [dealIdList, setDealIdList] = useState([]);
  const [psaLeadList, setPSALeadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clearFilterFlag,setClearFilterFlag] = useState(false);
  const [selections, setSelections] = useState({
    companyName: '',
    legalEntity: { key : '', value : ''},
    dealName: '',
    dealId: '',
  });
  const [selectedDealID,setSelectedDealID] = useState('');
  const [selectedDealName,setSelectedDealName]= useState('');

  const getFilterData = async() => {
    setLoading(true);
    const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/psaReports`;
    try{
      const response = await callApi.getApiData(url);
      console.log(response)
      if(response){
        setParentCompanyNameList(formatFilterData(response.data, 0));
        setLoading(false);
        setClearFilterFlag(false)
      }
    }catch(e){
      setLoading(false);
      setClearFilterFlag(false)
    }

  };

  const getPSANames = async (selectionsTemp) => {
    setLoading(true);
    const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/psaDropDownDetails?companyName=${selectionsTemp.companyName}`;
    
      try{
        const response = await callApi.getApiData(url);
        if(response){
          setFullData(response.data);
          setLegalEntityNamesList(
            formatFilterData(response.data.legalEntityNames, 1)
          );
          setDealIdList(formatFilterData(response.data.deals, 2));
          setDealNameList(formatFilterData(response.data.deals, 3));
          loadAllPsaLeads(response.data.associatedPSALeads);
          setLoading(false);
        }
      }catch(e){
        setLoading(false);
      }
  
  };

  const loadAllPsaLeads = (leadsList) => {
    let leads = [];
    leadsList.forEach((lead) => {
      if(lead.psaLeads[0]!==undefined && lead.psaLeads[0]!==null){
        leads.push(lead.psaLeads[0]);
      }
    });
    setPSALeadList(
      leads.reduce((unique, o) => {
        if (!unique.some((obj) => obj.bankerId === o.bankerId)) {
          unique.push(o);
        }
        return unique;
      }, [])
    );
  };

  const onFilterChange = (event, filterName) => {
    if (event.errorCode != 224) {
      let selectionsTemp = {
        companyName: selections?.companyName,
        legalEntity: selections?.legalEntity,
        dealName: selections?.dealName,
        dealId: selections?.dealId,
      };
      if (filterName === 'legalEntity') {
        selectionsTemp.legalEntity = fullData?.legalEntityNames.find(
          (entity) => entity.key == event?.target?.value
        );
        if(selectionsTemp.legalEntity==undefined){selectionsTemp.legalEntity={key:'',value:''}}
      } else if (filterName === 'dealName' || filterName === 'dealId') {
          if(event?.target?.value ==''){
            selectionsTemp.dealId = '';
            selectionsTemp.dealName = '';
          } else {
            if(filterName === 'dealName') {
              selectionsTemp.dealName = event?.target?.value;
            } else if(filterName === 'dealId'){
              selectionsTemp.dealId = event?.target?.value;
            }
            fullData?.deals.forEach((deal) => {
                if (deal.value == event?.target?.value) {
                  selectionsTemp.dealId = deal.key
                } else if (deal.key == event?.target?.value) {
                  selectionsTemp.dealName = deal.value
                } 
              })
          }
        }
      setSelections(selectionsTemp);
      if(selectionsTemp.legalEntity.key == '' && selectionsTemp.dealId == '' && selectionsTemp.dealName == ''){
        loadAllPsaLeads(fullData?.associatedPSALeads);
        
      } else {
        getPSANamesFromFullData(selectionsTemp);
      }
      setTimeout(() => {
        setLoading(false)
      }, 1000);
      
    }
  };

  const formatFilterData = (list, indicator) => {
    let newData = [];
    if (indicator == 0) {
      // for parent company
      list.forEach((element, index) => {
        if (element!==null && element!==undefined) {
          newData.push({ value: element, content: element }); 
        }
      });
    } else if (indicator == 1) {
      // for legal Entity
      list.forEach((element) => {
        if (element!==null && element!==undefined) {
        newData.push({ value: element.key, content: element.value });}
      });
    } else if (indicator == 2) {
      // for deal id
      list.forEach((element, index) => {
        if (element!==null && element!==undefined) {
        newData.push({ value: element.key, content: element.key });}
      });
    } else if (indicator == 3) {
      // for deal name
      list.forEach((element, index) => {
        if (element!==null && element!==undefined) {
        newData.push({ value: element.value, content: element.value });}
      });
    }
    let sortedData = newData.sort((a, b) => a.content.localeCompare(b.content));
    sortedData.unshift({ value: '', content: 'Select' });
    return sortedData;
  };

  const onParentCompanyChange = (event) => {
    setPSALeadList([]);
    setLegalEntityNamesList([]);
    setDealNameList([]);
    setDealIdList([]);
    setSelectedDealID('');
    setSelectedDealName('');
    const selectionsTemp = {
      companyName: '',
      legalEntity: {key : '', value : ''},
      dealName: '',
      dealId: '',
    };
    selectionsTemp.companyName = event?.target?.value;
    setSelections(selectionsTemp);
    getPSANames(selectionsTemp);
  };

  const getPSANamesFromFullData = (selections) => {
    let PSALeads = [];
    try {
      fullData?.associatedPSALeads.forEach((leads) => {
        if(selections.dealId == ''){
          // only legal entity is selected
          if (leads.legalEntites.key == selections.legalEntity.key) {
            PSALeads.push(leads.psaLeads[0]);
          }
        } else if (selections.legalEntity.key == ''){
          // only deal is selected :deal name or ID 
          if (selections.dealId == leads.deals.key){
            PSALeads.push(leads.psaLeads[0]);
          }
        } else {
          // Both deal and legal entity are selected
          if(leads.legalEntites.key == selections.legalEntity.key && selections.dealId == leads.deals.key ){
            PSALeads.push(leads.psaLeads[0]);
          }
        }
        const filteredPSALeads = PSALeads.reduce((unique, o) => {
          if (!unique.some((obj) => obj.bankerId === o.bankerId)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setPSALeadList(filteredPSALeads);
      });
    } catch (error) {
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  
  const tempUserRole = useUserRole();
  const columns = [
    {
      headerName: 'PSA Lead Name',
      key:'userName',
      fieldFor: 'first',
      dataType: 'string',
      type: 'info',
    },
    {
      headerName: 'View As',
      fieldFor: 'second',
      dataType: 'node',
      type: 'default',
      element:(banker)=>{
        return  <USBButton
        type="button"
        variant="primary"
        size="small"
        className="viewAsBtn"
        handleClick={() => {
          sessionStorage.setItem('view_as', JSON.stringify(banker));
          if (sessionStorage.getItem('remote_user')) {
            sessionStorage.removeItem('remote_user');
          }
          window.location.href = '/ReportingDashboard';
        }}
      >
        View As
      </USBButton>
      }
    },
  ]
  return (
    tempUserRole && tempUserRole!="employee" ? <_noaccess/> :
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="reports"
            text="Reports"
            breadcrumbs={[
              { id: 1, text: 'Reports', href: '/Reports/PSAFilter' },
              { id: 2, text: 'PSA Filter', href: '' },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}></USBColumn>
      </USBGrid>
      <USBGrid addClasses="content-frame content-bg-psa">
        {loading ? <Loader /> : null}
        <USBColumn largeSpan={16}>
          <h1 className="heading-h1">Report: PSA Filters</h1>
          <div className="page-details-wrap-psa">
            <div className="page-details-container-psa">Select Filters</div>
          </div>
          <div className="sorting-inputs-psa">
            <div className="filter-dropdowns-psa">
              {!clearFilterFlag && parentCompanyNameList.length > 0 ? (
                <USBSelect
                  id="companyName"
                  inputName="companyName"
                  name="parentCompanyName"
                  labelText="Company Name"
                  initValue={selections.companyName}
                  optionsArray={parentCompanyNameList}
                  handleChange={(event) => {
                    if(event?.nativeEvent?.type!="blur")
                      onParentCompanyChange(event);
                  }}
                  errorMessages={{ 224: 'Please select an option' }}
                />
              ) : (
                null
              )}
            </div>
            <div className="filter-dropdowns-psa">
              {!clearFilterFlag && legalEntityNamesList.length > 0 ? (
                <USBSelect
                  id="legalEntity"
                  inputName="legalEntity"
                  labelText="Legal Entity"
                  name="legalEntityName"
                  initValue={selections.legalEntity.key}
                  optionsArray={legalEntityNamesList}
                  handleChange={(e) => {
                    if(e?.nativeEvent?.type!="blur"){
                    setLoading(true)
                    onFilterChange(e, 'legalEntity');}
                  }}
                  errorMessages={{
                    224: 'Please select an option',
                  }}
                />
              ) : (
                null
              )}
            </div>
            <div className="filter-dropdowns-psa">
              { !loading && !clearFilterFlag && dealNameList.length > 0 ? (
                <USBSelect
                  id="dealName"
                  inputName="dealName"
                  labelText="Deal Name"
                  name="dealNameName"
                  initValue={selections.dealName}
                  optionsArray={dealNameList}
                  handleChange={(e) => {
                    if(e?.nativeEvent?.type!="blur"){
                    setLoading(true)
                    onFilterChange(e, 'dealName');}
                  }}
                  errorMessages={{
                    224: 'Please select an option',
                  }}
                />
              ) : (
                null
              )}
            </div>
            <div className="filter-dropdowns-psa dealNumber">
              {!loading && !clearFilterFlag && dealIdList.length > 0  ? (
                <USBSelect
                  id="dealNumber"
                  inputName="dealNumber"
                  initValue={selections.dealId}
                  labelText="Deal Number"
                  name="dealNumberName"
                  optionsArray={dealIdList}
                  handleChange={(e) => {
                    if(e?.nativeEvent?.type!="blur"){
                    setLoading(true)
                    onFilterChange(e, 'dealId');}
                  }}
                  errorMessages={{
                    224: 'Please select an option',
                  }}
                />
              ) : (
                null
              )}
            </div>

            {!loading && !clearFilterFlag && legalEntityNamesList.length > 0? (
              <div >
                <USBButton
                  type="button"
                  size="small"
                  id="clearFilterPSA"
                  name="clearfilter"
                  handleClick={() => {
                    setClearFilterFlag(true)
                    setDealIdList([]);
                    setLegalEntityNamesList([]);
                    setDealNameList([]);
                    setPSALeadList([]);
                    setSelections({
                      companyName: '',
                      legalEntity: { key : '' ,  value : ''},
                      dealName: '',
                      dealId: '',
                    })
                    setSelectedDealID('');
                    setSelectedDealName('');
                    getFilterData();
                  }}
                >
                  Clear filters
                </USBButton>
              </div>
            ) : (
              null
            )}
          </div>
          {!loading && !clearFilterFlag && psaLeadList.length > 0 ? (
            <div className="page-content-psa">
               <UsbTableData
                data={psaLeadList}
                columns={columns}
              />
            </div>
          ) : (
            null
          )}
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default PSA;
