import React from 'react';
import axios from 'axios';
import '../../App.css';
import './manage-user.css';
import {
  USBButton,
  USBSearchInput,
  USBIconSort,
  USBIconVerticalContextualMenu,
  USBPagination,
  USBDropdown,
  USBIconEdit,
  USBIconAdd,
  USBNotification,
  USBIconWarningSign,
  USBLink,
} from '@usb-shield/components-react/dist/cjs';
import componentConfig from '../../config/component-config.json';
import { connect } from 'react-redux';
import AutoCompleteDropdown from '../../components/shared/AutoCompleteDropdown/AutoCompleteDropdown';
import GraphQlQuery from '../../utilities/graphQL-query';
import Loader from '../../components/utilities/Loader';
import {
  getSvcServiceURL,
  getDocumentmgmtServiceURL,
  getSvcOldServiceURL,
  getAuthServiceURL,
  getConstants,
  getDocumentmgmtServiceURLuser
} from '../../config/connections';
import { UserRoleConfig } from '../../config/user-role-config';
import Helpers from '../../utilities/helper';
import LoadingHelper from '../../utilities/loading-helper';
import { transmitIframeMessage } from '../../components/utility/transmit-message';
import SubMenu from '../../components/shared/sub-menu/sub-menu';
import { ManageUserHelper } from './manage-user-helper';
import ConfirmationDialog from '../../components/shared/modal-wrapper';
import MoveDialog from '../../components/shared/moveDialog';
import ReportDownloadModal from '../../components/shared/report-download-modal';
import callApi from '../../components/utilities/callApi';
import {
  deactivateUser,
  reactivateUser,
  resendInvitation,
  unlock,
  deleteUser
} from '../../shared/thunk/profile-status-change-thunk';
import _noaccess from '../../pages/_noaccess'
import { getQS } from '../../components/utilities/Utils';
import {PermissionRoleContext} from '../../components/Hooks/usePermissionRoles';
import {FeatureFlagsContext} from '../../components/Hooks/useFeatureFlags';
import { endpoints } from '../../../config/connections';

class ManageUsers extends React.Component {
  userInformation = null;
  currentParentEIN = null;
  superAdminTableColumns = ['name', 'profileRole', 'email', 'status'];
  defaultTableCoumns = ['name', 'profileRole', 'email', 'status'];
  profileColumnsDataMapping = {
    name: 'NAME',
    parentCompanyName: 'PARENT COMPANY',
    profileRole: 'ROLE',
    email: 'EMAIL ADDRESS',
    status: 'STATUS',
  };

  profileListTableMetaData = {
    sortKey: 'lastName',
    sortValue: 'asc',
    offset: 0,
    limit: 12,
  };

  search = {
    searchBy: '',
    searchValue: '',
    topLeftSearchBy: '',
    topLeftSearchBoxValue: '',
    parentCompanyFilterValue: '',
    statusFilterValue: '',
    userTypeFilterValue: '',
    searchIntimated: null,
    isUnlistedVendorCallSuccess: false,
  };

  currentUserRole = undefined;
  topLeftSearch = ['Last name', 'Email address'];

  recordApiCount = 0;
  frequentVendorParentCompanyInformation = null;

  constructor(props) {
    super(props);
    this.onAddUserClick = this.onAddUserClick.bind(this);
    this.searchInputChange = this.searchInputChange.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.pageChangeEvent = this.pageChangeEvent.bind(this);
    this.displayReportModal = this.displayReportModal.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.resetDownloadNotification = this.resetDownloadNotification.bind(this);
    this.searchText = '';
    this.state = {
      cdcEmpUnlistedVenodorSearch: false,
      cdcEmpUnlistedVenodorSearchError: false,
      parentCompanyFilterDefaultValue: '',
      profileList: [],
      parentCompanyNameList: [],
      fullParentList:[],
      filteredParentCompanyNameList: [],
      selectedParentCompany: {},
      pageMeta: {},
      currentPageCount: 1,
      currentTableColumns: this.defaultTableCoumns,
      isSerachNotFound: true,
      isUnlistedVendorSelected: false,
      isApiCallError: false,
      unlistedVendorProfiles: [],
      currentPageCountForClientSidePageination: 1,
      totalNoOfPageForClientSidePagination: 0,
      loading: true,
      confirmDialog: {
        title: '',
        message: '',
        details:[],
        primaryText: '',
        open: false,
        profile: null,
      },
      moveDialog:{
        open : false,
        title : '',
        profile : null,
        pcList: []
      },
      newParentPermissions : [],
      newParentDetails : [],
      moveUserProfile : null,
      reportDialog: {
        title: '',
        body: '',
        primaryText: 'Download',
        open: false,
        isAdmin: false,
      },
      contributorMoved : false,
      statusChangeApiResponse: {
        success: false,
        error: false,
        message: '',
      },
      downloadApiResponse: {
        success: false,
        error: false,
        message: '',
      },
      showUserTypeFilter : false,
      statusArray : ['Active', 'Pending', 'Deactivated', 'Locked']
    };

    const appElement = document.querySelector('#root .App');
    if (appElement) {
      appElement.classList.add('sp-manage-user');
    }
  }

  clearProfileData() {
    this.props.dispatch({
      type: 'RESET_PREVIOUS_USER_DATA',
      payload: true,
    });
    this.props.dispatch({
      type: 'RESET_ENTITLEMENTS',
      payload: true,
    });
  }

  userParentCompanyDetails(data, data1) {
    this.props.dispatch({
      type: 'SET_PARENT_COMPANY_INFO',
      payload: {
        ein: data.getProfileList.profiles[0].parentEIN,
        parentCompanyName: data.getProfileList.profiles[0].parentCompanyName,
        businessProfileType: data1.businessProfileType,
        parentCompanyLegalEntityID:
          data.getProfileList.profiles[0].parentCompanyLegalEntityID,
        address: data.getProfileList.profiles[0].address,
        phoneNumber: data.getProfileList.profiles[0].phoneNumbers,
      },
    });
  }

  onAddUserClick = (e) => {
    this.clearProfileData();
    let path;
    if (this.currentUserRole.includes(UserRoleConfig.superAdmin)) {
      path = '/ManageUser/parent-company';
    } else if (
      this.currentUserRole.includes(UserRoleConfig.customer) ||
      (this.props &&
        this.props.parentCompany &&
        this.props.parentCompany.businessProfileType?.toLowerCase() ===
        'specialvendor')
    ) {
      path = '/ManageUser/user-type';
    } else {
      path = '/ManageUser/emp-reg';
    }
    // console.log('Path is :', path);
    this.props.history.push(path);
  };

  loadProfilesList(query) {
    this.setState({
      cdcEmpUnlistedVenodorSearchError: false,
    });
    this.setState((state) => {
      // Important: read `state` instead of `this.state` when updating.
      return {
        profileList: [],
        unlistedVendorProfiles: [],
        pageMeta: null,
        isSerachNotFound: false,
      };
    });
    LoadingHelper.inFlight();
    const graphURL = getSvcServiceURL({ service: 'graph' });
    axios
      .post(graphURL, query, { cdcType: 'servicing' })
      .then((res) => {
        // console.log('response=======', res.data);
        //FOOBAR
        const data = res.data.data;
        if (data.getProfileList === null) {
          this.search.searchIntimated = 'intimated';
          this.setState({
            isSerachNotFound: true,
          });
        }
        if (data.getProfileList !== null && data.getProfileList !== undefined) {
          if (
            data.getProfileList?.profiles?.length === 1 &&
            data.getProfileList?.profiles[0]?.parentEIN === 'Not Listed' &&
            this.state.cdcEmpUnlistedVenodorSearch
          ) {
            this.setState({
              cdcEmpUnlistedVenodorSearchError: true,
            });
          }
        }

        LoadingHelper.outFlight();
        try {
          if (data !== null && data !== undefined) {
            this.setState((state) => {
              return {
                profileList: data.getProfileList?.profiles,
                pageMeta: data.getProfileList?.pageMeta,
              };
            });
            //skkSKK
            if (this.state.isUnlistedVendorSelected) {
              let pageSize = 12;
              let numberOfpages;
              this.loadAllUnlistedVendorProfilesList();
              setTimeout(() => {
                numberOfpages = Math.ceil(
                  this.allUnlistedProfileList?.length / pageSize
                )||1;
                this.setState((state) => {
                  return {
                    totalNoOfPageForClientSidePagination: numberOfpages,
                    isUnlistedVendorSelected: true,
                  };
                });
              }, 1000);
            }
          } else {
            console.log('exception for filter ---->');
          }
        } catch (e) {
          console.log('exception for filter INSIDE CATCH---->' + e);
          this.setState((state) => {
            return {
              profileList: [],
              pageMeta: undefined,
            };
          });
        }
        if (
          this.search !== undefined &&
          this.search.topLeftSearchBoxValue !== ''
        ) {
          if (
            data.getProfileList !== null &&
            data.getProfileList !== undefined
          ) {
            // this.insertNewParentCompanyListOnSearchSubmitEvent();
          }
        }
        if (this.frequentVendorParentCompanyInformation) {
          this.props.dispatch({
            type: 'SET_PARENT_COMPANY_INFO',
            payload: {
              ein: this.frequentVendorParentCompanyInformation.parentEIN,
              parentCompanyName:
                this.frequentVendorParentCompanyInformation.parentCompanyName,
              parentCompanyLegalEntityID:
                this.frequentVendorParentCompanyInformation
                  .parentCompanyLegalEntityID,
              businessProfileType:
                this.frequentVendorParentCompanyInformation.businessProfileType,
              profileRole:
                this.frequentVendorParentCompanyInformation.profileRole,
              address: this.frequentVendorParentCompanyInformation.address,
              phoneNumbers:
                this.frequentVendorParentCompanyInformation.phoneNumbers,
            },
          });
        }
      })
      .catch((error) => {
        LoadingHelper.outFlight();
        this.setState({
          profileList: [],
          pageMeta: null,
          isSerachNotFound: false,
        });
        if (
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 400
        ) {
          this.search.searchIntimated = 'intimated';
          this.setState({
            isSerachNotFound: true,
          });
        }

        // console.log('profileList response====', this.state.profileList);
        console.log(
          'There is an error for loadProfilesList in API call.' + error
        );
      });
  }

  searchInputChange = (e) => {
    // console.log('Input Change', e.target.value);
    this.search.topLeftSearchBoxValue = e.target.value;
  };


  componentDidMount() {
    this.userInformation = this.props.currentUser;
    // console.log(this.props);
    this.currentUserRole = this.userInformation.custom_data.role.toLowerCase();
    if (!this.currentUserRole.includes(UserRoleConfig.superAdmin)) {
      this.getCurrentUserRole();
    } else {
      this.getParentCompanyNameList();
      this.setState({
        currentTableColumns: this.superAdminTableColumns,
      });
    }
    const searchElementRef = document.getElementById(
      'manage-user-search-input'
    );
    if (searchElementRef) {
      searchElementRef.addEventListener(
        'keypress',
        this.onTopLeftSearchEnterHandler
      );
    }
  }

  getCurrentUserRole() {
    const graphURL = getSvcServiceURL({ service: 'graph' });
    const query = GraphQlQuery.getCurrentUserRole(this.props.currentUser.sub);
    LoadingHelper.inFlight();
    this.setState((state) => {
      return {
        isApiCallError: false,
      };
    });
    axios
      .post(graphURL, query, { cdcType: 'servicing' })
      .then((res) => {
        LoadingHelper.outFlight();
        this.filterParentCompanyNameAndEin([]);
        const resData = res.data.data.getProfileWithParentComType;
        const data = res.data.data;
        if (data && data.getProfileWithParentComType) {
          if (
            data.getProfileWithParentComType?.profileRole?.toLowerCase() ===
            'site administrator' &&
            data.getProfileWithParentComType?.businessProfileType?.toLowerCase() ===
            'vendor'
          ) {
            this.currentUserRole = 'frequent vendor site admin';
            this.currentParentEIN = data.getProfileWithParentComType.ein;
            this.frequentVendorParentCompanyInformation =
              data.getProfileWithParentComType;
            this.props.dispatch({
              type: 'IS_FREQUENT_VENDOR',
              payload: true,
            });
          }
          this.getParentEinForCustomer().then((res) => {
            const data = res.data.data;
            this.currentParentEIN = data.getProfileList?.profiles[0]?.parentEIN;
            this.props.dispatch({
              type: 'SET_LOGIN_USER_PARENT_COMPANY_INFO',
              payload: {
                number: data.getProfileList.profiles[0].parentEIN,
                parentCompanyName:
                  data.getProfileList.profiles[0].parentCompanyName,
                parentCompanyLegalEntityID:
                  data.getProfileList.profiles[0].parentCompanyLegalEntityID,
              },
            });
            this.userParentCompanyDetails(data, resData);
            if (this.frequentVendorParentCompanyInformation) {
              this.frequentVendorParentCompanyInformation.address =
                data.getProfileList?.profiles[0]?.address;
              this.frequentVendorParentCompanyInformation.phoneNumbers =
                data.getProfileList?.profiles[0]?.phoneNumbers;
            }
            this.requestProfileListData();
          });
        }
      })
      .catch((error) => {
        LoadingHelper.outFlight();
        this.setState((state) => {
          return {
            isApiCallError: true,
          };
        });
      });
  }

  async getParentEinForCustomer() {
    LoadingHelper.inFlight();
    const query = GraphQlQuery.customerQuery(this.props.currentUser.sub);
    const graphURL = getSvcServiceURL({ service: 'graph' });
    const response = await axios.post(graphURL, query, {
      cdcType: 'servicing',
    });
    LoadingHelper.outFlight();
    return response;
  }

  componentWillUnmount() {
    // document.querySelector('#root .App').classList.remove('sp-manage-user');
    window.removeEventListener('keypress', this.onTopLeftSearchEnterHandler);
  }

  getParentCompanyNameList = () => {
    //   const config = {
    //     cdcType: "docmanagement",
    //     // headers:{
    //     //     'jwt':`${sessionStorage.getItem('tok')}`,
    //     //     'user-id':`${sessionStorage.getItem('assignedUser')}`
    //     // }
    // }
    const url = getDocumentmgmtServiceURL({ service: 'getAllParentCompanies' });
    LoadingHelper.inFlight();
    this.setState((state) => {
      return {
        isApiCallError: false,
      };
    });
    axios
      .get(url,{ cdcType : "docmanagement"})
      .then((res) => {
        if(res?.status==401){
          sessionStorage.removeItem("remote_user")
          sessionStorage.removeItem("portfolioFilters")
          if (this.currentUserRole &&
            this.currentUserRole.includes(UserRoleConfig.customer)) {
              // sessionStorage.clear();
              window.location.href = '/EmployeeRedirect?tp=lg';    
          } else {
            window.location.href = '/';
            sessionStorage.clear();
          }
        } else {
          LoadingHelper.outFlight();
          const sortedParentList = Helpers.sortData(
            res.data,
            'parentCompanyName'
          );
          this.filterParentCompanyNameAndEin(sortedParentList)
          this.setState({
            parentCompanyNameList: sortedParentList,
            fullParentList : sortedParentList
          });
        }
      })
      .catch((error) => {
        LoadingHelper.outFlight();
        this.setState((state) => {
          return {
            isApiCallError: true,
          };
        });
      });
  };

  filterParentCompanyNameAndEin = (userList) => {
    // console.log("userlist", userList)
    let tempParentCompanyList = []
    let loadingFlag = true;
    if (userList?.length > 0) {
      userList.forEach(user => {
        tempParentCompanyList.push({
          ein: user.ein,
          parentCompanyName: user.parentCompanyName,
          parentCompanyLegalEntityID: user.parentCompanyLegalEntityID,
          address: user.address,
          phoneNumber: user.phoneNumbers ? user.phoneNumbers : '',
          businessProfileType: user.businessProfileType
        })
      });
      // .map((pcObj) => { return { content: pcObj.parentCompanyName, value: JSON.stringify(pcObj) } })
      let filteredParentCompanylist = tempParentCompanyList.reduce((unique, o) => {
        if (!unique.some((obj) => obj.ein === o.ein)) {
          unique.push({ content: o.parentCompanyName, value: o.ein });
        }
        return unique;
      }, []);
      // console.log("filter List", filteredParentCompanylist)
      this.setState((state) => {
        return state.filteredParentCompanyNameList = [filteredParentCompanylist, tempParentCompanyList]
      }, (e) => { loadingFlag = false })
      if (!loadingFlag) {
        this.setState((state) => { return state.loading = false })
      }
    } else {
      this.getParentCompanyNameList()
    }
  }

  onTopLeftSearchEnterHandler = (e) => {
    //skk
    // console.log('handle event - ', e);
    if (e.key === 'Enter' && e.target.value) {
      this.search.topLeftSearchBoxValue = e.target.value.trim();
      this.search.parentCompanyFilterValue = '';
      this.search.searchIntimated = 'intimated';
      this.setState({
        parentCompanyFilterDefaultValue: '',
      });
      this.setState({
        cdcEmpUnlistedVenodorSearch: true,
      });
      this.requestProfileListData();
    }
  };

  requestProfileListData = () => {
    // console.log(UserRoleConfig);
    const userId = this.userInformation.sub;
    if (
      this.currentUserRole.includes(UserRoleConfig.frequentVendor) ||
      this.currentUserRole === 'frequent vendor site admin'
    ) {
      const query = GraphQlQuery.frequentVendorQuery(
        userId,
        this.profileListTableMetaData,
        this.search,
        this.currentParentEIN
      );
      this.loadProfilesList(query);
      this.setState({
        currentTableColumns: this.defaultTableCoumns,
      });
    } else if (this.currentUserRole.includes(UserRoleConfig.superAdmin)) {
      const query = GraphQlQuery.cdcEmployeeQuery(
        userId,
        this.profileListTableMetaData,
        this.search
      );
      this.loadProfilesList(query);
      this.setState({
        currentTableColumns: this.superAdminTableColumns,
      });
    } else if (this.currentUserRole.includes(UserRoleConfig.customer)) {
      const query = GraphQlQuery.customerQuery(
        null,
        this.profileListTableMetaData,
        this.search,
        this.currentParentEIN
      );
      this.loadProfilesList(query);
      this.setState({
        currentTableColumns: this.defaultTableCoumns,
      });
    }
  };

  getAllProfilebyLastName() {
    let userId;
    let metaData = {
      sortKey: 'lastName',
      sortValue: 'asc',
      offset: 1,
      limit: 40,
    };
    const query =
      GraphQlQuery.cdcEmployeeViewGetAllProfileListByLastNameOrEmailAddressQuery(
        userId,
        metaData,
        this.search
      );
    const graphURL = getSvcServiceURL({ service: 'graph' });
    axios
      .post(graphURL, query, { cdcType: 'servicing' })
      .then((res) => {
        // console.log('TempList Parent Company ::', res.data);
      })
      .catch((error) => {
        console.log('API error');
      });
  }

  handleImpersonate = async (profile) => {
    if (sessionStorage.getItem('view_as')) {
      sessionStorage.removeItem('view_as');
    }
    sessionStorage.setItem('remote_user', JSON.stringify(profile));
    sessionStorage.setItem('sessionFilters',JSON.stringify({requirementName:"",reportingEntity:"",parentCompany:"",asOfDate:"",cbDate:"",submittedDate:"",requirementNameReport:"",reportingEntityReport:"",asOfDateReport:"",submittedDateReport:"",dealName:"",dealId:"",status:"Not Submitted",syndicationFundName:"",following:""}));

    const permissionURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/allowedPages`;
    let response = await callApi.getApiDataRequirement(permissionURL);
    
    if(response.status===200){
      const permissionDetails = response.data;
      if(permissionDetails?.includes("inbox") && !permissionDetails?.includes("portfolio")){
        let profileTemp=profile;
        profileTemp.onlyInbox=true;
        sessionStorage.setItem('remote_user', JSON.stringify(profileTemp));
        window.location.href = '/Redirect';
      }
      else 
       if(!permissionDetails?.includes("inbox") && permissionDetails?.includes("portfolio")){
        let profileTemp=profile;
        profileTemp.onlyPortfolio=true;
        sessionStorage.setItem('remote_user', JSON.stringify(profileTemp));
        window.location.href = '/Redirect';
      }
      else 
      if(permissionDetails?.includes("portfolio"))
      {
        window.location.href = '/Redirect';
      }
      else{
        alert("Cannot impersonate user at this moment. Please try again.")
      }
    }
  };

  insertNewParentCompanyListOnSearchSubmitEvent() {
    // this.getAllProfilebyLastName();
    /* this.setState((state) => {
      return {
        parentCompanyNameList: [],
      }
    });*/
    let tempList = [];
    let ein = [];
    for (var index = 0; index < this.state.profileList?.length; index++) {
      let obj = {};
      obj['ein'] = this.state.profileList[index].parentEIN;
      obj['parentCompanyName'] =
        this.state.profileList[index].parentCompanyName;
      if (!ein.includes(obj.ein)) {
        ein.push(obj.ein);
        tempList.push(obj);
      }
    }
    if (
      tempList !== undefined &&
      tempList.length === 1 &&
      tempList[0].ein === 'Not Listed'
    ) {
    } else {
      this.setState((state) => {
        return {
          parentCompanyNameList: [],
        };
      });
      this.setState((state) => {
        return {
          parentCompanyNameList: tempList,
        };
      });
    }
  }
  deleteUser = () => {
    const userID = getQS('id');
    LoadingHelper.inFlight();
    this.setState({
      loading : true
    })
    this.props
      .dispatch(
        deleteUser(
          this.state.confirmDialog.profile,
          userID
          )
      )
      .then((res) => {
        if(res.status == 200){
          this.setState({
            loading : false,
            statusChangeApiResponse: {
              success: true,
              error: false,
              message: 'You’ve deleted the user.',
            },
          });
          setTimeout(()=>{
            this.resetNotification()
          },5000)
          transmitIframeMessage.scrollUp();
          setTimeout(() => {
            this.requestProfileListData();
            LoadingHelper.outFlight();
          }, 1000);
        } else {
          LoadingHelper.outFlight();
        this.setState({
          statusChangeApiResponse: {
            loading : false,
            success: false,
            error: true,
            message:
              res.response.data.Error,
          },
        });
        transmitIframeMessage.scrollUp();
        }
        
        //SKK

      })
      .catch((err) => {
        LoadingHelper.outFlight();
        this.setState({
          laoding : false,
          statusChangeApiResponse: {
            success: false,
            error: true,
            message:
              'That didn’t quite work. Please try deactivating the user again.',
          },
        });
        transmitIframeMessage.scrollUp();
      });

  }
  handleEdit = (e, parentList) => {
    // console.log('Edit Profile Id:', e);
    this.clearProfileData();
    const isAdmin = this.currentUserRole.includes(UserRoleConfig.superAdmin) ? true : false;
    this.props.history.push({
      pathname: '/ManageUser/edit-profile', state: {
        profileId: e,
        parentCompanyList: parentList,
        isAdmin: isAdmin
      }
    });
  };

  handleSorting = (e, sortValue) => {
    if (!this.state.isUnlistedVendorSelected) {
      if (e !== 'email') {
        this.profileListTableMetaData.offset = 1;
        this.profileListTableMetaData.sortValue = sortValue;
        this.profileListTableMetaData.sortKey = e === 'name' ? 'lastName' : e;
        this.requestProfileListData();
        this.setState({
          currentPageCount: 1,
        });
        // console.log('Sort by:', e);
      }
    } else {
      this.sortArrayByProperty(e, sortValue);
    }
  };

  sortArrayByProperty = (e, sortValue) => {
    let sortedList = [];
    if (e === 'profileRole')
      sortedList = this.state.unlistedVendorProfiles.sort(
        this.compareValues('firstName', sortValue)
      );
    if (e === 'name')
      sortedList = this.state.unlistedVendorProfiles.sort(
        this.compareValues('firstName', sortValue)
      );
    this.setState((state) => {
      return {
        unlistedVendorProfiles: sortedList,
      };
    });
    // console.log(sortedList);
  };

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  allUnlistedProfileList = [];

  loadAllUnlistedVendorProfilesList() {
    LoadingHelper.inFlight();
    this.allUnlistedProfileList = [];
    let metaData = {
      sortKey: 'lastName',
      sortValue: 'asc',
      offset: 1,
      limit: 10,
    };
    let query;
    if (
      this.props.parentCompany &&
      this.props.parentCompany.ein !== 'Not Listed'
    )
      query = GraphQlQuery.getAllUnlistedVendor(
        this.props.parentCompany.ein,
        metaData,
        this.search
      );
    else
      query = GraphQlQuery.getAllUnlistedVendor(
        this.search.parentCompanyFilterValue,
        metaData,
        this.search
      );
    const graphURL = getSvcServiceURL({ service: 'graph' });
    axios
      .post(graphURL, query, { cdcType: 'servicing' })
      .then((res) => {
        this.allUnlistedProfileList =
          res.data.data.getUnlistedVendorList.profiles;
        if (this.allUnlistedProfileList.length)
          this.loadListPageData(
            this.state.currentPageCountForClientSidePageination
          );

        this.setState({
          isUnlistedVendorCallSuccess: true,
        });
        LoadingHelper.outFlight();
      })
      .catch((error) => {
        LoadingHelper.outFlight();
        this.setState({
          isUnlistedVendorCallSuccess: false,
        });
        if (
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 400
        ) {
          this.setState({
            isUnlistedVendorCallSuccess: true,
          });
        }

        console.log(
          'allUnlistedProfileList response===',
          this.allUnlistedProfileList
        );
        console.log(
          'There is an error for allUnlistedProfileList in API call.' + error
        );
      });
  }

  clientPaginationData = (e) => {
    this.profileListTableMetaData.offset = 1;
    this.state.currentPageCount = 1;
    //this.autoResetNotification();
    this.search.userTypeFilterValue =
      e.target.value === undefined ? e.target.innerText : e.target.value;
    let pageSize = 12;
    let numberOfpages;
    let dropdownValuetoCheck =
      e.target.value === undefined ? e.target.innerText : e.target.value;
    if (dropdownValuetoCheck === 'Vendor') {
    this.loadAllUnlistedVendorProfilesList();
      setTimeout(() => {
        numberOfpages = Math.ceil(
          this.allUnlistedProfileList.length / pageSize
        );
        this.setState((state) => {
          return {
            totalNoOfPageForClientSidePagination: numberOfpages,
            isUnlistedVendorSelected: true,
          };
        });
      }, 1000);
    } else {
      this.setState((state) => {
        return {
          totalNoOfPageForClientSidePagination: 0,
          currentPageCountForClientSidePageination: 1,
          isUnlistedVendorSelected: false,
        };
      });
    }
    if (
      dropdownValuetoCheck === 'Employee' ||
      dropdownValuetoCheck === 'Frequent Vendor'
    )
      this.requestProfileListData();
  };

  loadListPageData = (currentPage) => {
    let pageList = [];
    this.setState((state) => {
      return {
        unlistedVendorProfiles: [],
      };
    });
    let begin = (currentPage - 1) * 12;
    let end = begin + 12;
    pageList = this.allUnlistedProfileList.slice(begin, end);
    this.setState((state) => {
      return {
        unlistedVendorProfiles: pageList||[],
      };
    });
  };

  pageChangeEventForClientSidePageination = (e) => {
    //this.autoResetNotification();
    this.loadListPageData(e);
    this.setState((state) => {
      return {
        currentPageCountForClientSidePageination: e,
      };
    });
  };

  handleItemClick = (e) => {
    //this.autoResetNotification();
    this.search.topLeftSearchBy =
      e.target.value === undefined ? e.target.innerText : e.target.value;
  };

  pageChangeEvent = (e) => {
    //this.autoResetNotification();
    this.profileListTableMetaData.offset =
      this.profileListTableMetaData.limit * (e - 1) + 1;
    this.requestProfileListData();
    this.setState({ currentPageCount: e });
  };

  onParentCompanyClick = (e) => {
    //this.autoResetNotification();
    // console.log('selected parent company name:', e);
    this.search.searchIntimated = 'intimated';
    this.search.parentCompanyFilterValue = e.ein;
    this.setState({
      parentCompanyFilterDefaultValue: e.parentCompanyName,
      selectedParentCompany: e,
      showUserTypeFilter : true,
      statusArray :  ['All', 'Active', 'Pending', 'Deactivated', 'Locked']
    });
    this.props.dispatch({
      type: 'SET_PARENT_COMPANY_SELECTION_MANAGE_USER',
      payload: e.ein,
    });
    this.requestProfileListData();
  };

  onDropdownHeightChangeTransmitMessage = (open) => {
    transmitIframeMessage.sendOverLayMessage(open);
  };

  statusFilterChange = (e) => {
    //this.autoResetNotification();
    let filterValue =
      e.target.value === undefined ? e.target.innerText : e.target.value;
    if (filterValue === 'All') this.search.statusFilterValue = '';
    else this.search.statusFilterValue = filterValue;
    this.profileListTableMetaData.offset = 1;
    this.state.currentPageCount = 1;
    this.requestProfileListData();
  };

  getMenuItemsForRowActions = (profile,permissions,flags) => {
    if (profile.parentEIN === 'NOT LISTED') {
      return ManageUserHelper.getMenuItemsForUnlistedVendorProfileTableActions(
        profile.status,
        permissions,
        flags,
        profile.parentEIN
      );
    }

    return ManageUserHelper.getMenuItemsForProfileTableActions(
      profile.status,
      this.props.currentUser.pid,
      permissions,
      flags,
      profile.parentEIN
    );
  };

  getMenuItemsForUnlistedVendorRowActions = (profile,permissions,flags) => {
    return ManageUserHelper.getMenuItemsForUnlistedVendorProfileTableActions(
      profile.status,
      permissions,
      flags,
      profile.parentEIN
    );
  };

  reactivateUser = () => {
    LoadingHelper.inFlight();
    let profileRole = this.state.confirmDialog.profile.profileRole;
    this.props
      .dispatch(
        reactivateUser(
          this.state.confirmDialog.profile,
          this.props?.parentCompany?.ein,
          this.props.parentCompanySelection,
          this.props.currentUser.pid
        )
      )
      .then((res) => {
        if (profileRole === 'Site Administrator') {
          this.setState({
            statusChangeApiResponse: {
              success: true,
              error: false,
              message: 'You’ve reactivated the user.',
            },
          });
          setTimeout(()=>{
            this.resetNotification()
          },5000)
        } else {
          this.setState({
            statusChangeApiResponse: {
              success: true,
              error: false,
              message:
                'You’ve reactivated the user. You’ll need to edit their permissions before they can use their account again.',
            },
          });
        }
        setTimeout(()=>{
          this.resetNotification()
        },5000)
        transmitIframeMessage.scrollUp();
        // console.log('Reactivate API call response : ', res);
        setTimeout(() => {
          this.requestProfileListData();
          LoadingHelper.outFlight();
        }, 1000);
      })
      .catch((err) => {
        LoadingHelper.outFlight();
        this.setState({
          statusChangeApiResponse: {
            success: false,
            error: true,
            message:
              'That didn’t quite work. Please try reactivating the user again.',
          },
        });
        transmitIframeMessage.scrollUp();
      });
  };

  deactivateUser = () => {
    LoadingHelper.inFlight();
    this.props
      .dispatch(
        deactivateUser(
          this.state.confirmDialog.profile,
          this.props?.parentCompany?.ein,
          this.props.parentCompanySelection,
          this.props.currentUser.pid
        )
      )
      .then((res) => {
        this.setState({
          statusChangeApiResponse: {
            success: true,
            error: false,
            message: 'You’ve deactivated the user.',
          },
        });
        setTimeout(()=>{
          this.resetNotification()
        },5000)
        transmitIframeMessage.scrollUp();
        //SKK
        setTimeout(() => {
          this.requestProfileListData();
          LoadingHelper.outFlight();
        }, 1000);

        // console.log('Deactivate API call response : ', res);
      })
      .catch((err) => {
        LoadingHelper.outFlight();
        this.setState({
          statusChangeApiResponse: {
            success: false,
            error: true,
            message:
              'That didn’t quite work. Please try deactivating the user again.',
          },
        });
        transmitIframeMessage.scrollUp();
      });
  };

  resendInvitationToUser = () => {
    LoadingHelper.inFlight();
    this.props
      .dispatch(resendInvitation(this.state.confirmDialog.profile))
      .then((res) => {
        LoadingHelper.outFlight();
        this.setState({
          statusChangeApiResponse: {
            success: true,
            error: false,
            message: 'You’ve resent the user’s enrollment invitation.',
          },
        });
        setTimeout(()=>{
          this.resetNotification()
        },5000)
        transmitIframeMessage.scrollUp();
      })
      .catch((err) => {
        LoadingHelper.outFlight();
        if(err.response.data.error.code=="0371.3568"){
          this.setState({
            statusChangeApiResponse: {
              success: false,
              error: true,
              message:err.response.data.error.message
            },
          });
        } else {
          this.setState({
            statusChangeApiResponse: {
              success: false,
              error: true,
              message:
                'That didn’t quite work. Check the user’s email address and try sending the invitation again.',
            },
          });
        }
        transmitIframeMessage.scrollUp();
      });
  };

  unlock = (profile) => {
    LoadingHelper.inFlight();
    this.props
      .dispatch(unlock(profile))
      .then((res) => {
        LoadingHelper.outFlight();
        this.setState({
          statusChangeApiResponse: {
            success: true,
            error: false,
            message: 'You’ve unlocked the user.',
          },
        });
        setTimeout(()=>{
          this.resetNotification()
        },5000)
        transmitIframeMessage.scrollUp();
        // console.log('Unlock API call response : ', res);
        this.requestProfileListData();
      })
      .catch((err) => {
        LoadingHelper.outFlight();
        this.setState({
          statusChangeApiResponse: {
            success: false,
            error: true,
            message:
              'That didn’t quite work. Please try unlocking the user again.',
          },
        });
        transmitIframeMessage.scrollUp();
      });
  };

  onStatusChange = () => {
    if (
      this.state.confirmDialog.primaryText.toLowerCase() === 'deactivate user'
    ) {
      console.log('deactivate the user');
      this.resetModal();
      this.deactivateUser();
    } else if (
      this.state.confirmDialog.primaryText.toLowerCase() === 'resend invitation'
    ) {
      console.log('resend invitation to the user');
      this.resetModal();
      this.resendInvitationToUser();
    } else if (
      this.state.confirmDialog.primaryText.toLowerCase() === 'reactivate user'
    ) {
      console.log('reactivate the user');
      this.resetModal();
      this.reactivateUser();
    } else if (
      this.state.confirmDialog.primaryText.toLowerCase() === 'delete user'
    ) {
      console.log('delete the user');
      this.resetModal();
      this.deleteUser();
    }
  };

  fetchUserProfile = (profile) => {
        this.setState({
          loading:true
        })
        const graphURL = getSvcServiceURL({ service: 'graph' });
        const query = GraphQlQuery.getProfile(profile.profileID);
        return axios.post(graphURL, query, { cdcType: "servicing" }).then((response) => {
          let tempProfile = response.data.data.getProfile
          let pcDetails = this.state.fullParentList.filter((pc)=>pc.ein==tempProfile.parentEIN)[0]
          if(pcDetails == undefined){
            this.setState({
              loading : false,
              statusChangeApiResponse: {
                success: false,
                error: true,
                message: 'Unable to fetch current company details.',
              },
            });
          }else{
            let filteredPCList = this.state.fullParentList.filter((pc) => (pcDetails.businessProfileType + '').toLocaleLowerCase()==(pc.businessProfileType+ '').toLocaleLowerCase() && pcDetails.ein!=pc.ein).map(pc => { return {content:pc.parentCompanyName,value:pc.ein}})
          return axios.post(getAuthServiceURL({ service: 'userdetails' }), {userID : profile.userID}, { cdcType: "auth" })
                .then(data => {
                    if (data.data.data.enrollmentDetail.enrollmentStatus) {
                      this.setState({
                        moveUserProfile : {...tempProfile,authUserEnrollmentStatus : data.data.data.enrollmentDetail.enrollmentStatus},
                        loading:false,
                        moveDialog : {
                          open: true,
                          title: 'Are you sure you want to move user?',
                          profile : { user : profile, company : pcDetails },
                          pcList : [{content:"Select",value:'',disabled:true},...filteredPCList],
                        }
                      })
                    }})
          }
        })
  }

  getPermissionsWithEin = (e) => {
    const ein = e?.target?.value;
    const permissionsURL = getDocumentmgmtServiceURL({
      service: 'getProfilePermissionsforParentEIN',
      userid: `${ein}`
    });
    let pcDetails = this.state.fullParentList.filter((pc)=>pc.ein==ein)[0]
    let profileTemp = this.state.moveUserProfile
    profileTemp.address = pcDetails?.address
    profileTemp.parentEIN=pcDetails?.ein 
    profileTemp.parentCompanyName=pcDetails?.parentCompanyName
    profileTemp.parentCompanyLegalEntityID=pcDetails?.parentCompanyLegalEntityID
    if(profileTemp.profileRole=="Contributor"){
      if(pcDetails.businessProfileType=="Vendor"){
        profileTemp.usbRelationship = "Frequent_Vendor_Contributor__Contact"
      } else if(pcDetails.businessProfileType=="SpecialVendor" || pcDetails.businessProfileType=="Customer" ) {
        profileTemp.usbRelationship = "Company_Contributor__Contact"
      }
    } else if(profileTemp.profileRole=="Site Administrator"){
      if(pcDetails.businessProfileType=="Vendor"){
        profileTemp.usbRelationship = "Frequent_Vendor_Site_Administrator__Contact"
      } else if(pcDetails.businessProfileType=="SpecialVendor" || pcDetails.businessProfileType=="Customer" ) {
        profileTemp.usbRelationship = "Company_Site_Administrator__Contact"
      }
    }
    axios.get(permissionsURL, {cdcType: "docmanagement"}).then((response)=>{
      this.setState({
        newParentPermissions : response.data,
        moveUserProfile : profileTemp
      })
    })
  }

  updatePersonalDetails = async () => {
    this.setState({
      loading : true,
    });
    const serviceUpdatePayload = {
      profileRoleChanged: false,
      requestType: {
          serviceType: "CDC_PROF_MAINT",
          serviceSubType: "EDIT_PROFILE"
      },
      data: {
          intent: "EDIT_PROFILE",
          lastEditedBy: sessionStorage.getItem("loggedUser"),
          sourceType: "CDC",
          shouldUpdateEmailInAuthDB: false,
          shouldUpdateMoblileNumberInAuthDB: false,
          ...this.state.moveUserProfile,
        }
    }

    const docManagementPayload = {
      profileId: this.state.moveUserProfile.profileID,
      source: "CDC",
      userId: this.state.moveUserProfile.userID,
      emailId: this.state.moveUserProfile.email,
      vendorRelationship: [
          {
              vendorName: this.state.moveUserProfile.parentCompanyName,
              vendorNumber: this.state.moveUserProfile.parentEIN,
              vendorLegalEntityID: this.state.moveUserProfile.parentEIN,
              vendorRole: this.state.moveUserProfile.profileRole
          }
      ],
      permissions: [
          {
              name: "CDC",
              parentEntitys: this.state.moveUserProfile.profileRole=="Contributor" ? this.state.newParentPermissions : []
          }
      ],
      firstName: this.state.moveUserProfile.firstName,
      lastName: this.state.moveUserProfile.lastName,
      middleName: this.state.moveUserProfile.middleName,
      mobileNumber: [this.state.moveUserProfile.phoneNumbers.mobilePhone]
  }
    let serviceUpdate = await axios.post(getSvcOldServiceURL({ service: 'update' }), serviceUpdatePayload, { cdcType: "servicing_Update" });

    let docManagmentUpdate = await axios.put(getDocumentmgmtServiceURL({ service: 'updateProfilePermission', userid: this.state.moveUserProfile.userID }),
    docManagementPayload, { cdcType: "docmanagement" });

    if(serviceUpdate && docManagmentUpdate){
      return true;
    } else {
      return false;
    }
  }

  moveUser = async () => {
    let arePersonalDetailsUpdated = await this.updatePersonalDetails()
    if(arePersonalDetailsUpdated){
      if(this.state.moveUserProfile.profileRole=="Contributor"){
        this.setState((prevState) => ({
          moveDialog:{
          ...prevState.moveDialog,
          open:false,
          },
        }));
        setTimeout(() => {
          // this.handleEdit(this.state.moveUserProfile.profileID,this.state.fullParentList)
          this.clearProfileData();
          const isAdmin = this.currentUserRole.includes(UserRoleConfig.superAdmin) ? true : false;
          this.props.history.push({
            pathname: '/ManageUser/edit-profile', state: {
              profileId: this.state.moveUserProfile.profileID,
              parentCompanyList: this.state.fullParentList,
              isAdmin: isAdmin,
              wasUserMoved : true
            }
    });
        }, 200); 
      } else {
        this.setState({
          loading : false,
          statusChangeApiResponse: {
            success: true,
            error: false,
            message: 'Updated user details',
          },
        });
      }
      this.requestProfileListData();
      this.resetModal();
    } else {
      this.setState({
        loading : false,
        statusChangeApiResponse: {
          success: false,
          error: true,
          message: 'Error updating details',
        },
      });
      this.resetModal();
    }
  }

  resetModal = () => {
    /* Fix for defect CSWP-3361 */
    this.setState((prevState) => ({
      confirmDialog: {
        ...prevState.confirmDialog,
        open: false,
      },
      moveDialog:{
      ...prevState.moveDialog,
      open:false,
      },
      // moveUserProfile : null,
      // newParentPermissions : [],
    }));

    // this.setState({
    //   confirmDialog: {
    //     title: '',
    //     message: '',
    //     primaryText: '',
    //     open: false,
    //     profile: null
    //   }
    // })
  };

  resetNotification = () => {
    this.setState({
      statusChangeApiResponse: {
        success: false,
        error: false,
        message: '',
      },
      isApiCallError: false,
      cdcEmpUnlistedVenodorSearchError: false,
      cdcEmpUnlistedVenodorSearch: false,
    });
  };

  resetNotificationUnlisted = () => {
    this.setState({
      cdcEmpUnlistedVenodorSearchError: false,
      cdcEmpUnlistedVenodorSearch: false,
    });
  };

  /*autoResetNotification = () => {
    setInterval(() => {
        this.resetNotification();
    }, 5000);
  }*/

  onCancel = () => {
    console.log('Go to Manage user');
    this.resetModal();
  };

  onSubMenuDropdownItemSelected = (value, profile) => {
    switch (value.toLowerCase()) {
      case 'delete': 
      let details = [`Full Name : ${profile.firstName} ${profile.lastName}`, `User ID : ${(profile.userID +'').split('@')[0]}`]
      if(profile.email){
        details.push(`Email : ${profile.email}`)
      }
         this.setState({
          confirmDialog: {
            open: true,
            title: `Are you sure you want to delete?`,
            details : details,
            message: 'Once you delete the following user it cannot be restored.', 
            primaryText: 'Delete user',
            profile: profile,
          },
        });
        transmitIframeMessage.adjustSafariStyle();
        break ;
      case 'move user':
          this.fetchUserProfile(profile)
          // this.setState({
          //   moveDialog : {
          //     open: true,
          //     title: 'Are you sure you want to move user?',
          //     profile : profile,
          //     pcList : [{content:"Select",value:'',disabled:true},...this.state.filteredParentCompanyNameList[0]],
          //   }
          // })
        break;
      case 'edit':
        // console.log(this.state.filteredParentCompanyNameList)
        this.handleEdit(profile?.profileID, this.state.filteredParentCompanyNameList);
        break;

      case 'impersonate':
        //  this.setState({
        //   confirmDialog: {
        //     open: true,
        //     title: 'Are you sure you want to impersonate this user? ',
        //     message: "You will be viewing their data till you exit impersonate.",
        //     primaryText: 'Impersonate user',
        //     profile: profile,
        //   },
        // });

        this.handleImpersonate(profile);
        break;
      
      case 'deactivate':
        transmitIframeMessage.scrollToPosition();
        this.setState({
          confirmDialog: {
            open: true,
            title: 'Are you sure you want to deactivate this user? ',
            details:[],
            message:
              "They'll no longer have access to the portal and reporting requirements. We'll save their info in case you want to  reactivate them again.",
            primaryText: 'Deactivate user',
            profile: profile,
          },
        });
        transmitIframeMessage.adjustSafariStyle();
        break;
      case 'reactivate':
        transmitIframeMessage.scrollToPosition();
        this.setState({
          confirmDialog: {
            open: true,
            title: 'Reactivate this user? ',
            details:[],
            message:
              'Once you reactivate them, you can update their personal info and permissions.',
            primaryText: 'Reactivate user',
            profile: profile,
          },
        });
        transmitIframeMessage.adjustSafariStyle();
        break;
      case 'resend invitation':
        transmitIframeMessage.scrollToPosition();
        this.setState({
          confirmDialog: {
            open: true,
            title: 'Resend enrollment invitation? ',
            details:[],
            message:
              'The user will receive an email at the address you have on file with steps on how to set up their portal account.',
            primaryText: 'Resend invitation',
            profile: profile,
          },
        });
        transmitIframeMessage.adjustSafariStyle();
        break;
      case 'unlock':
        this.unlock(profile);
      default:
        break;
    }
  };

  getStatusSelectorName(profile) {
    // if(col === 'status'){
    if (profile.status?.toLowerCase() === 'deactivated') {
      return 'sp-mu-profile-state-deactivated';
    }
    return '';
    // }
  }
  resetReportDialog() {
    this.setState({
      reportDialog: {
        title: '',
        body: '',
        primaryText: 'Download',
        open: false,
      },
    });
  }
  displayReportModal(reportType) {
    let modalObj = {
      open: true,
    };
    if (reportType == 'admin') {
      modalObj.title = 'Download parent company site administrators report.';
      modalObj.body = (
        <div>
          To download the report, first choose your preferred format from the{' '}
          <strong>File type</strong> menu.
        </div>
      );
      modalObj.isAdmin = true;
    } else {
      if (
        this.userInformation &&
        this.currentUserRole.includes(UserRoleConfig.superAdmin)
      ) {
        modalObj.title = 'Download portal users and permissions report.';
        modalObj.body = (
          <div>
            To download the report, first select a parent company from the{' '}
            <strong>Parent company</strong> dropdown.Then choose your preferred
            format from the <strong>File type</strong> menu.
          </div>
        );
      } else {
        modalObj.title = 'Download portal users and permissions report.';
        modalObj.body = (
          <div>
            To download the report, first choose your preferred format from the{' '}
            <strong>File type</strong> menu.
          </div>
        );
      }
    }

    this.setState((prevState) => ({
      reportDialog: {
        ...prevState.reportDialog,
        ...modalObj,
      },
    }));
    console.log('[Manage Users] displayReportModal()');
  }
  resetDownloadNotification() {
    this.setState({
      downloadApiResponse: {
        success: false,
        error: false,
        message: '',
      },
    });
  }
  downloadReport(reportData) {
    //SKKREPORT
    this.setState((prevState) => ({
      reportDialog: {
        ...prevState.reportDialog,
        open: false,
      },
    }));
    LoadingHelper.inFlight();
    const searchElementRef = reportData.fileType;
    let data = '';
    let contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    let b64Data = '';

    let query;

    if (
      reportData.company !== undefined &&
      reportData.company !== null &&
      reportData.company !== ''
    ) {
      query = GraphQlQuery.getExcelReport(reportData.company);
    } else {
      query = GraphQlQuery.getExcelReport(this.props.parentCompany.ein);
    }
    const graphURL = getSvcServiceURL({ service: 'graph' });
    axios
      .post(graphURL, query, { cdcType: 'servicing' })
      .then((res) => {
        b64Data = res.data.data.getManageUserExcelReport;

        //----------------------------------------------------------

        const byteCharacters = atob(b64Data); //  BASE 64 Decoding
        const byteArrays = [];
        let sliceSize = 512;

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        //-----------------------------------------------------------
        //let blob = b64toBlob(b64Data, contentType);
        const blobUrl = URL.createObjectURL(blob);
        setTimeout(() => {
          LoadingHelper.outFlight();
        }, 1000);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // if (searchElementRef === "Microsoft Excel (xlsx)") {
          window.navigator.msSaveOrOpenBlob(blob, 'manageuserreport.xls');
          // }
        } else {
          window.open(blobUrl);
        }
        this.setState((prevState) => ({
          downloadApiResponse: {
            ...prevState.downloadApiResponse,
            success: true,
            message: 'You’ve successfully downloaded the report.',
          },
        }));
        setTimeout(()=>{
          this.resetDownloadNotification()
        },5000)
      })
      .catch((error) => {
        this.setState((prevState) => ({
          reportDialog: {
            ...prevState.reportDialog,
            open: false,
          },
          downloadApiResponse: {
            ...prevState.downloadApiResponse,
            error: true,
            message:
              'There was a problem downloading the report. Please try again.',
          },
        }));
        LoadingHelper.outFlight();
      });
  }

  render() {
    const shieldSearchInputBox = {
      [componentConfig.USBSearchInput.id]: 'searchInput',
      [componentConfig.USBSearchInput.placeholder]: 'Search',
      [componentConfig.USBSearchInput.initInputValue]: this.searchText,
      [componentConfig.USBSearchInput.name]: 'Search',
      [componentConfig.USBSearchInput.handleChange]: this.searchInputChange,
    };
    const submitBtnProps = {
      [componentConfig.USBButton.variant]: 'primary',
      [componentConfig.USBButton.handleClick]: this.onAddUserClick,
    };
  return (
    <FeatureFlagsContext.Consumer>
      {(flags)=>
    <PermissionRoleContext.Consumer>
      {(permissions) =>
      <React.Fragment>
        {this.state.loading ? <Loader /> : null}        
        <ConfirmationDialog
          open={this.state.confirmDialog.open}
          title={this.state.confirmDialog.title}
          body={this.state.confirmDialog.message}
          multiLineBody={this.state.confirmDialog.details}
          toggle={this.onCancel}
          primaryButtonText={this.state.confirmDialog.primaryText}
          primaryClickHandler={this.onStatusChange}
        />

        <MoveDialog
          open={this.state.moveDialog.open}
          title={this.state.moveDialog.title}
          profile={this.state.moveDialog.profile}
          pcList={this.state.moveDialog.pcList}
          toggle={this.onCancel}
          getPermissionsWithEin={this.getPermissionsWithEin}
          primaryButtonText={'Move User'}
          primaryClickHandler={this.moveUser}
          newParentPermissions={this.state.newParentPermissions}
        />

        <ReportDownloadModal
          open={this.state.reportDialog.open}
          companyList={this.state.parentCompanyNameList}
          isSuperAdmin={
            this.userInformation &&
            this.currentUserRole.includes(UserRoleConfig.superAdmin) &&
            !this.state.reportDialog.isAdmin
          }
          title={this.state.reportDialog.title}
          body={this.state.reportDialog.body}
          selectedCompnay={this.state.selectedParentCompany}
          toggle={() => this.resetReportDialog()}
          primaryButtonText={this.state.reportDialog.primaryText}
          primaryClickHandler={this.downloadReport}
        />

        <div className="sp-container-fluid sp-manage-user">
          <div className="sp-mu-title sp-mr-b-16 ">
            <h1 className="sp-page-title">Manage users</h1>
            {this.state.contributorMoved ? (
              <USBNotification
                addClasses="sp-width-100 sp-mr-b-16 sp-mr-t-12"
                variant={
                  'confirmation'
                }
                handleClose={this.resetNotification}
                handleClick={()=>this.handleEdit(this.state.moveUserProfile.profileID,this.state.fullParentList)}
                notificationData={[
                  {
                    text: 'Updated user details. To assign specific permisions go to :',
                    linkText:'Edit Profile',
                  },
                ]}>
              </USBNotification>
            ) : null}
            {this.state.statusChangeApiResponse.success ||
              this.state.statusChangeApiResponse.error ? (
              <USBNotification
                addClasses="sp-width-100 sp-mr-b-16 sp-mr-t-12"
                variant={
                  this.state.statusChangeApiResponse.success
                    ? 'confirmation'
                    : 'error'
                }
                handleClose={this.resetNotification}
                notificationData={[
                  {
                    text: this.state.statusChangeApiResponse.message,
                  },
                ]}
              ></USBNotification>
            ) : null}
            {this.state.isApiCallError ? (
              <USBNotification
                addClasses="sp-width-100 sp-mr-b-16 sp-mr-t-12"
                variant={'error'}
                handleClose={this.resetNotification}
                notificationData={[
                  {
                    text: 'We’re having trouble accessing your users’ information. Please refresh your browser and try again.',
                  },
                ]}
              ></USBNotification>
            ) : null}
            {this.state.cdcEmpUnlistedVenodorSearchError ? (
              <USBNotification
                addClasses="sp-width-100 sp-mr-b-16 sp-mr-t-12"
                variant={'error'}
                handleClose={this.resetNotificationUnlisted}
                notificationData={[
                  {
                    text: 'Please use the Parent company and User type filters to search for this user.',
                  },
                ]}
              ></USBNotification>
            ) : null}
            {this.state.downloadApiResponse.success ||
              this.state.downloadApiResponse.error ? (
              <USBNotification
                addClasses="sp-width-100 sp-mr-b-16 sp-mr-t-12"
                variant={
                  this.state.downloadApiResponse.success
                    ? 'confirmation'
                    : 'error'
                }
                handleClose={this.resetDownloadNotification}
                notificationData={[
                  {
                    text: this.state.downloadApiResponse.message,
                  },
                ]}
              ></USBNotification>
            ) : null}

            <div className="sp-mu-search-container">
              <USBDropdown
                label="Search by"
                id="manage-user-search-dropdown"
                items={this.topLeftSearch}
                handleChange={(e) => this.handleItemClick(e)}
                defaultSelected=""
              />
              <USBSearchInput
                {...shieldSearchInputBox}
                id="manage-user-search-input"
              ></USBSearchInput>
            </div>
          </div>

          <div className="sp-row sp-justify-content sp-content-display sp-mr-b-32">
            {this.userInformation &&
              this.currentUserRole.includes(UserRoleConfig.superAdmin) ? (
              <div className="sp-body-medium sp-col-7">
                To make changes to an existing user’s information, first select
                their parent company from the <strong> Filter by </strong>{' '}
                dropdown. Then select <strong> Edit </strong> from the menu to
                update their info.
                <br></br>
                <br></br>
                If you’d like to add a new user, select the{' '}
                <strong> Add a user </strong> button.
              </div>
            ) : this.userInformation &&
              this.currentUserRole.includes(UserRoleConfig.frequentVendor) ? (
              <div className="sp-body-medium sp-col-7">
                Select <strong> Edit </strong> to update an existing user’s
                role, permissions or personal information.
                <br></br>
                <br></br>
                If you’d like to add a new user, select the{' '}
                <strong> Add a user </strong> button. You can add anyone from
                your organization.
              </div>
            ) : (
              <div className="sp-body-medium sp-col-7">
                Select <strong> Edit </strong> to update an existing user’s
                role, permissions or personal information.
                <br></br>
                <br></br>
                If you’d like to add a new user, select the{' '}
                <strong> Add a user </strong> button. You can add anyone from
                your organization or a vendor.
              </div>
            )}
            <div className="sp-body-medium sp-col-5 report-download-manage-users">
              <div className="report-download-title-manage-users">
                DOWNLOAD A REPORT
              </div>
              <USBLink
                id={'report-link'}
                className="download-link-manage-users"
                handleClick={() => this.displayReportModal('')}
              >
                Portal users and permissions
              </USBLink>
              {this.userInformation &&
                this.currentUserRole.includes(UserRoleConfig.superAdmin)
                ? //   <button type="button" class="download-link-manage-users"  onClick={() => this.displayReportModal("admin")}>
                //   Parent company site administrators
                // </button>
                ''
                : ''}
            </div>
          </div>
          <div className="sp-mr-b-8">Filter by:</div>
          <div className="sp-mu-filter-container">
            {/* {JSON.stringify(this.state.parentCompanyNameList)} */}
            <div className="sp-mu-filters">
              {this.userInformation &&
                this.currentUserRole.includes(UserRoleConfig.superAdmin) ? (
                <div className="sp-mu-filter-item">
                  {this.state.parentCompanyNameList.length > 0 ? (
                    <AutoCompleteDropdown
                      onItemSelect={(e) => this.onParentCompanyClick(e)}
                      comboboxId="combobox"
                      autocompleteInputId="autocomplete-input"
                      listboxID="listbox"
                      arrowId="combobox-arrow"
                      listItems={this.state.parentCompanyNameList}
                    />
                  ) : (
                    ' '
                  )}
                </div>
              ) : (
                ' '
              )}

              {(this.userInformation &&
                (this.currentUserRole.includes(UserRoleConfig.customer) ||
                  (this.state.showUserTypeFilter && this.currentUserRole.includes(UserRoleConfig.superAdmin)))) ||
                (this.props &&
                  this.props.parentCompany &&
                  this.props.parentCompany.businessProfileType?.toLowerCase() ===
                  'specialvendor') ? (
                <div className="sp-mu-filter-item dropdown-border">
                  <USBDropdown
                    label="User type"
                    dropdownType="text"
                    id="manage-user-unlisted-vendor-one"
                    items={
                      this.props &&
                        this.props.parentCompany &&
                        this.props.parentCompany.businessProfileType?.toLowerCase() ===
                        'specialvendor'
                        ? ['Employee', 'Frequent Vendor']
                        : ['Employee', 'Frequent Vendor', 'Vendor']
                    }
                    defaultSelected={
                      this.currentUserRole &&
                        this.currentUserRole.includes(UserRoleConfig.customer)
                        ? 'Employee'
                        : null
                    }
                    handleChange={(e) => this.clientPaginationData(e)}
                  />
                </div>
              ) : (
                ' '
              )}

              <div className="sp-mu-filter-item dropdown-border">
                <USBDropdown
                  label="Status"
                  dropdownType="text"
                  id="manage-user-unlisted-vendor-two"
                  items={(this.currentUserRole+'').includes(UserRoleConfig.superAdmin) ? this.state.statusArray : ['All', 'Active', 'Pending', 'Deactivated', 'Locked'] }
                  handleChange={(e) => this.statusFilterChange(e)}
                />
              </div>
            </div>
            <div className="sp-add-button">
              <USBButton id={'addUsrSubmitBtn'} {...submitBtnProps}>
                Add a user
              </USBButton>
            </div>
          </div>

          <div className="sp-row">
            <div className="sp-col">
              <table className="sp-mr-b-16 sp-table-profile-list">
                <thead>
                  <tr>
                    {this.state.currentTableColumns.map((row, i) =>
                      row === 'name' || row === 'profileRole' ? (
                        <td
                          className={`sp-mu-superhead ${row === 'parentCompanyName'
                            ? 'sp-table-parent-col-min-width'
                            : ''
                            }`}
                          key={i}
                        >
                          {this.profileColumnsDataMapping[row]} &nbsp;  &nbsp; 
                          <div className="sp-mu-holder">
                            <div className="sp-mu-sort-div">
                              <USBButton
                                name="ascending sort"
                                size="small"
                                type="button"
                                variant="naked"
                                iconName={'sort'}
                                iconAssistiveText={'Sort by Ascending'}
                                iconData={{ path: [{ d: 'M6 8l4-4 4 4H6z' }] }}
                                iconViewBox={'0 0 20 20'}
                                handleClick={() =>
                                  this.handleSorting(row, 'asc')
                                }
                              />
                            </div>
                            <div className="sp-mu-sort-div">
                              <USBButton
                                name="descending sort"
                                type="button"
                                size="small"
                                variant="naked"
                                iconName={'sort'}
                                iconAssistiveText={'Sort by Descending'}
                                iconData={{ path: [{ d: 'M6 8l4 4 4-4z' }] }}
                                iconViewBox={'0 0 20 20'}
                                handleClick={() =>
                                  this.handleSorting(row, 'desc')
                                }
                              />
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td
                          className={`sp-mu-superhead ${row === 'parentCompanyName'
                            ? 'sp-table-parent-col-min-width'
                            : ''
                            }`}
                          key={i}
                        >
                          {this.profileColumnsDataMapping[row]}
                        </td>
                      )
                    )}
                    <td></td>
                  </tr>
                </thead>

                <tbody>
                  {this.state.profileList?.length &&
                    !this.state.isUnlistedVendorSelected ? (
                    this.state.profileList.map((profile, rowId) => (
                      <tr
                        key={rowId}
                        className={`sp-table-row ${this.getStatusSelectorName(
                          profile
                        )}`}
                      >
                        {this.state.currentTableColumns.map((col, j) => (
                          <td
                            className={`table-content1 sp-table-content-outline sp-body-small
                            ${col === 'email' ? 'sp-email-link-color' : ''} ${col === 'name' ? 'sp-mu-table-profile-name' : ''
                              }
                             ${col === 'status'
                                ? 'sp-mu-table-status-column'
                                : ''
                              }
                             ${col === 'status' &&
                                profile[col]?.toLowerCase() === 'locked'
                                ? 'sp-mu-table-profile-status-locked'
                                : ''
                              }`}
                            key={j}
                          >
                            {col === 'name' ? (
                              profile.firstName + ' ' + profile.lastName
                            ) : col === 'status' &&
                              profile[col]?.toLowerCase() === 'locked' ? (
                              <>
                                {' '}
                                <USBIconWarningSign
                                  addClasses="sp-sub-menu-icon-spacing"
                                  colorVariant="warning"
                                />{' '}
                                {profile[col]}{' '}
                              </>
                            ) : profile[col]?.toLowerCase() === 'pending' ? (
                              <div className={'status-peending'}>
                                {' '}
                                {profile[col]}
                              </div>
                            ) : (
                              profile[col]
                            )}
                          </td>
                        ))}

                        <td
                          style={{ cursor: 'pointer' }}
                          className="sp-table-content-outline sp-body-small"
                        >
                          <SubMenu
                            label={
                              <USBIconVerticalContextualMenu colorVariant="default" />
                            }
                            menuItems={this.getMenuItemsForRowActions(profile,permissions.permissionDetails,flags.featureDetails)}
                            onItemSelected={this.onSubMenuDropdownItemSelected}
                            optionalData={profile}
                          />
                        </td>
                      </tr>
                    ))
                  ) : this.search.searchIntimated === 'intimated' &&
                    this.state.isSerachNotFound &&
                    !this.state.isUnlistedVendorSelected ? (
                    <tr className="sp-table-row">
                      <td className="table-content1 sp-table-content-outline">
                        No search results found.
                      </td>
                      <td className="table-content1 sp-table-content-outline"></td>
                      <td className="table-content1 sp-table-content-outline"></td>
                      <td className="table-content1 sp-table-content-outline"></td>
                    </tr>
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}

                  {this.state.unlistedVendorProfiles.length &&
                    this.state.isUnlistedVendorSelected ? (
                    this.state.unlistedVendorProfiles.map((profile, rowId) => (
                      <tr
                        key={rowId}
                        className={`sp-table-row ${this.getStatusSelectorName(
                          profile
                        )}`}
                      >
                        {this.state.currentTableColumns.map((col, j) => (
                          <td
                            className={`table-content1 sp-table-content-outline sp-body-small
                            ${col === 'email' ? 'sp-email-link-color' : ''} ${col === 'name' ? 'sp-mu-table-profile-name' : ''
                              }
                            ${col === 'status'
                                ? 'sp-mu-table-status-column'
                                : ''
                              }
                            ${col === 'status' &&
                                profile[col]?.toLowerCase() === 'locked'
                                ? 'sp-mu-table-profile-status-locked'
                                : ''
                              }`}
                            key={j}
                          >
                            {col === 'name' ? (
                              profile.firstName + ' ' + profile.lastName
                            ) : col === 'status' &&
                              profile[col]?.toLowerCase() === 'locked' ? (
                              <>
                                {' '}
                                <USBIconWarningSign
                                  addclasses="sp-sub-menu-icon-spacing"
                                  colorVariant="warning"
                                />
                                {profile[col]}
                              </>
                            ) : profile[col]?.toLowerCase() === 'pending' ? (
                              <div className={'status-peending'}>
                                {' '}
                                {profile[col]}
                              </div>
                            ) : (
                              profile[col]
                            )}
                          </td>
                        ))}

                        <td
                          style={{ cursor: 'pointer' }}
                          className="sp-table-content-outline"
                        >
                          <SubMenu
                            label={
                              <USBIconVerticalContextualMenu colorVariant="default" />
                            }
                            menuItems={this.getMenuItemsForUnlistedVendorRowActions(
                              profile,
                              permissions.permissionDetails,flags.featureDetails
                            )}
                            onItemSelected={this.onSubMenuDropdownItemSelected}
                            optionalData={profile}
                          />
                        </td>
                      </tr>
                    ))
                  ) : this.state.unlistedVendorProfiles.length === 0 &&
                    this.state.isUnlistedVendorSelected &&
                    this.state.isUnlistedVendorCallSuccess ? (
                    <tr className="sp-table-row">
                      <td className="table-content1 sp-table-content-outline">
                        No search results found.
                      </td>
                      <td className="table-content1 sp-table-content-outline"></td>
                      <td className="table-content1 sp-table-content-outline"></td>
                      <td className="table-content1 sp-table-content-outline"></td>
                    </tr>
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
              {this.state.pageMeta &&
                this.state.pageMeta.total &&
                !this.state.isUnlistedVendorSelected ? (
                <div className="sp-pagination sp-mr-b-56">
                  <USBPagination
                    id={'profileListPage'}
                    currentPage={this.state.currentPageCount}
                    totalPages={Math.ceil(
                      this.state.pageMeta.total /
                      this.profileListTableMetaData.limit
                    )}
                    data={this.state.profileList}
                    pageSize={this.state.profileList.length - 1}
                    paginationAriaLabel="Pagination Navigation"
                    handlePageChange={this.pageChangeEvent}
                    backwardButtonAriaLabel="Previous page"
                    forwardButtonAriaLabel="Next page"
                    forwardIconTitle="Next page"
                    backwardIconTitle="Previous page"
                    currentPageAriaLabel="Current page"
                    totalPagesAriaLabel="Total pages"
                    addClasses="pagination-component"
                    paginationLabelFrom="Page"
                    paginationLabelOf="of"
                  ></USBPagination>
                </div>
              ) : (
                ''
              )}

              {this.state.unlistedVendorProfiles.length > 0 &&
                this.state.isUnlistedVendorSelected &&
                this.state.isUnlistedVendorCallSuccess ? (
                <div className="sp-pagination sp-mr-b-56">
                  <USBPagination
                    id={'profileListPage'}
                    currentPage={
                      this.state.currentPageCountForClientSidePageination
                    }
                    totalPages={this.state.totalNoOfPageForClientSidePagination}
                    data={this.state.unlistedVendorProfiles}
                    pageSize={this.state.unlistedVendorProfiles.length - 1}
                    paginationAriaLabel="Pagination Navigation"
                    handlePageChange={
                      this.pageChangeEventForClientSidePageination
                    }
                    backwardButtonAriaLabel="Previous page"
                    forwardButtonAriaLabel="Next page"
                    forwardIconTitle="Next page"
                    backwardIconTitle="Previous page"
                    currentPageAriaLabel="Current page"
                    totalPagesAriaLabel="Total pages"
                    addClasses="pagination-component"
                    paginationLabelFrom="Page"
                    paginationLabelOf="of"
                  ></USBPagination>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
     }
     </PermissionRoleContext.Consumer>
  }
     </FeatureFlagsContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.user,
    parentCompany: state.parentCompanyInformation.parentCompany,
    parentCompanySelection:
      state.parentCompanyInformation.parentCompanySelection,
    globalLoadingIcon: state.showLoading,
  };
};
export default connect(mapStateToProps)(ManageUsers);
