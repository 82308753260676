import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';


import USBTable from '@usb-inner-src/react-table';
import { Checkbox } from 'primereact/checkbox';

import actionlogo from '../../assets/images/rightArrow.png';
import dateAlertIcon from '../../../assets/img/dateAlert.png';
import portalIndicator from '../../../assets/img/portal.png';
import {formatByBytes} from '../utilities/Utils';
import { USBLoader } from '@usb-shield/components-react/dist/cjs';

const PaginatedTable = ({ fullTableData, statusValue, getCBDocs, filterData, filters,currentPageTemp,setCurrentPageTemp,features, ...props}) => {
    const paginationLabels = {
        previousButton: 'Previous page',
        nextButton: 'Next page',
        unit: 'Page',
        preposition: 'of',
        rowDropdown: 'Per page',
    }

    const defaultTableHeaderconfigs = {
        isSortable: false,
        wrapContent: true,
        dataType: 'node',
        sortValues: ['Oldest', 'Newest'],
        headerAlignment: 'left',
    }

    const tableHeaders = [
        {
            headerName: 'DUE DATE',
            fieldFor: 'first',
            ...defaultTableHeaderconfigs,
        },

        {
            headerName: 'PARENT COMPANY',
            fieldFor: 'second',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'DEAL NAME / DEAL ID',
            fieldFor: 'third',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'DOCUMENT TYPE',
            fieldFor: 'fourth',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'CLOSING BINDER DATE',
            fieldFor: 'fifth',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'RECEIVED DATE',
            fieldFor: 'sixth',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'STATUS',
            fieldFor: 'seventh',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'FOLLOW',
            fieldFor: 'eighth',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'ACTION',
            fieldFor: 'ninth',
            ...defaultTableHeaderconfigs,
        },
    ]

    const notSubmittedTableHeaders = [
        {
            headerName: 'DUE DATE',
            fieldFor: 'first',
            ...defaultTableHeaderconfigs,
        },

        {
            headerName: 'PARENT COMPANY',
            fieldFor: 'second',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'DEAL NAME / DEAL ID',
            fieldFor: 'third',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'DOCUMENT TYPE',
            fieldFor: 'fourth',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'CLOSING BINDER DATE',
            fieldFor: 'fifth',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'STATUS',
            fieldFor: 'sixth',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'FOLLOW',
            fieldFor: 'seventh',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'ACTION',
            fieldFor: 'eighth',
            ...defaultTableHeaderconfigs,
        },
    ]

    if(features.length > 0 && features.includes("feature_filecount_visible")){
        tableHeaders.splice(8,0,{
            headerName: 'FILE COUNT',
            fieldFor: 'tenth',
            ...defaultTableHeaderconfigs,
        })
        notSubmittedTableHeaders.splice(7,0,{
            headerName: 'FILE COUNT',
            fieldFor: 'ninth',
            ...defaultTableHeaderconfigs,
        })
    }

    const [rangeArray, setRangeArray] = useState(['25', '50', '75', '100']);
   
    // const [rangeArray, setRangeArray] = useState(['25 Per page', '50 Per page', '75 Per page', '100 Per page']);
    const [currentPage, setCurrentPage] = useState(currentPageTemp);
    const [pageSize, setPageSize] = useState(25);
    
    useEffect(()=>{
            if (fullTableData.length != 0 && props.setFileCountIds) {
            const getPageIds = (array, pageNumber, pageSize) => {    
                const startIndex = (pageNumber - 1) * pageSize;
                const pageItems = array.slice(startIndex, startIndex + pageSize);
                return pageItems.reduce((idString,item) => idString + item.id + ',','');
            };
            const ids = getPageIds(fullTableData, currentPageTemp,pageSize);
            props.setFileCountIds(ids)
        }
    });
 
    const updateRangeArray = (pageCount) => {
        setPageSize(parseInt(pageCount));
        let dropdownSpans = document.getElementsByClassName('dropdown__btn-selection selected')
        if(dropdownSpans && fullTableData && fullTableData.length>24){
          dropdownSpans[0].childNodes[0].data = dropdownSpans[1].childNodes[0].data = pageCount
        }
    };

    const refreshTable = async () => {
        let data = await getCBDocs("");
        if(data!=undefined){
            filterData(filters,data)
        }
    }

    const follow = (follow, item,) => {
        const data = {
            userId: sessionStorage.getItem('loggedUser'),
            subscribed: follow,
            documentId: item.id,
            emailId: JSON.parse(sessionStorage.getItem('userData')).emailId,
            name:
                JSON.parse(sessionStorage.getItem('userData')).firstName +
                ' ' +
                JSON.parse(sessionStorage.getItem('userData')).lastName,
        };
        if (item.id) {
            setCurrentPage(currentPageTemp)
            const listUrl = `${endpoints.apigee}/document-management/cdc/requirements/v1/requirements-subscribe`;
            callApi
                .postApiLoggedUser(listUrl, data)
                .then((response) => {
                    refreshTable()
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };
    const followUp = (item) => {
        const maxCount = sessionStorage.getItem('maxCount')
            ? JSON.parse(sessionStorage.getItem('maxCount'))
            : 100;
        const followingCount = sessionStorage.getItem('followingCount')
            ? JSON.parse(sessionStorage.getItem('followingCount'))
            : 0;
        if(item.following=="true"){
            follow("false",item)
        } else {
            if(followingCount > maxCount){
                if(confirm(`You are already following ${maxCount} documents. Are you sure you want to follow?`)){
                    follow("true",item)
                }
            } else {
                follow("true",item)
            }
        }
    };

    const paginationtableData = () => {
        let paginationData = [];
        if (fullTableData.length != 0) {
            paginationData = [];
            fullTableData.map((item) => {
                paginationData.push(
                    tblContentTemplate(item)
                );
            });
        } else {
            if(features.length > 0 && features.includes("feature_filecount_visible")){
                return [{ first: '-', second: '-', third: '-', fourth: '-', fifth: '-', sixth: '-', seventh: '-', eighth: '-', ninth: '-', tenth : '-' }]
            } else {
            return [{ first: '-', second: '-', third: '-', fourth: '-', fifth: '-', sixth: '-', seventh: '-', eighth: '-', ninth: '-'}]
            }
        }
        return paginationData;
    }

    const tblContentTemplate = (item) => {

        // Column 1

        let dateHtml;
        let dueHtml;
        const docDate = item.dueDate;
        const docdueDate = moment(item.dueDate, 'YYYYMMDD').format('YYYYMMDD');
        const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');

        if (docDate != null) {
            const days = moment(docdueDate, 'YYYYMMDD').diff(moment(today, 'YYYYMMDD'), 'days');
            if (days === 0 && item.status === 'Not Submitted') {
                dueHtml = <div className="date-details">Due today</div>;
            } else if (days < 0 && item.status === 'Not Submitted') {
                dueHtml = (
                    <div className="font-red date-details">
                        <span className="date-alert">
                            <img src={dateAlertIcon} />
                        </span>
                        Past due
                    </div>
                );
            } else if (days > 0 && item.status !== 'Completed') {
                dueHtml = (
                    <div className="date-details">
                        Due in&nbsp;
                        {days}
                        &nbsp;
                        {days > 1 ? 'days' : 'day'}
                    </div>
                );
            }
            dateHtml = (
                <div>
                    {moment(docdueDate).format('MM/DD/YYYY')}
                    <div>{dueHtml}</div>
                </div>
            );
        } else dateHtml = '';

        // Column 2

        const parentHtml = <div>
            <div className="span-bold">{item.metadata.parentCompanyName}</div>
            <div className="data-table-subcell">{item.metadata.reportingEntity}</div>
        </div>

        // Column 3

        let dealNametext = '';
        let dealNumberText;
        if (item.metadata.showSyndicationFundInd === true) {
            dealNametext = item.metadata.syndicationFundName;
            dealNumberText = item.metadata.syndicationFund;
        } else {
            if ((item.metadata.dealName === '' || null)) {
                dealNametext = 'Not applicable';
                dealNumberText = '';
            } else {
                dealNametext = item.metadata.dealName;
                dealNumberText = item.metadata.dealId;
            }
        }

        const dealHtml = <div>
            <div className="span-bold">{dealNametext}</div>
            <div className="data-table-subcell">{dealNumberText}</div>
        </div>

        // Column 4

        const docTypeHtml = <div className="data-table-cell">
            <div className="span-bold">{item.category ? item.category : ''}</div>
            <div className="data-table-subcell">{item.requirementName} - {item.closingBinderSequenceNumber}</div>
            {item.portalSubmitted ? (
                <div className="expand-column-portal">
                    <div className="portal-indicator">
                        <img src={portalIndicator} />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>

        // Column 5

        const closingbinderdateHtml = <div>{item?.closingBinderDate ? moment.utc(item?.closingBinderDate).format('MM/DD/YYYY') : '-'}</div>

        // Column 6

        const receivedDateHtml = <div>{item.submittedDate ? moment(item.submittedDate).format('MM/DD/YYYY HH:mm:ss') : '-'}</div>

        // Column 7

        const statusType = item.status;
        let statusHtml;
        if (statusType.toLowerCase() === 'needs action')
            statusHtml = (
                <div className="font-red to-uppercase">
                    {statusType}
                    <span className="notification">#</span>
                </div>
            );
        else if (statusType.toLowerCase() === 'submitted')
            statusHtml = (
                <div className="data-table-cell to-uppercase">
                    <div>{statusType}</div>
                    {item.submittedDate &&
                        item.submittedDate !== '' &&
                        item.submittedDate !== null ? (
                        <div>{moment(item.submittedDate).format('MM/DD/YYYY')}</div>
                    ) : (
                        ''
                    )}
                </div>
            );
        else
            statusHtml = (
                <div className="data-table-cell to-uppercase">{statusType}</div>
            );

        // Column 8

        const docId = item.id;
        const disabledID = sessionStorage.getItem('userrole') === 'employee' && !sessionStorage.getItem('remote_user') && sessionStorage.getItem('userData') && JSON.parse(sessionStorage.getItem('userData')).emailId ? false : true;
        const followHtml = <div className="expand-column" data-testid="chck-box">
            <Checkbox
                onChange={() =>
                    followUp(item)
                }
                checked={item.following == "true" ? true : false}
                disabled={disabledID}
                ariaLabelledBy="mark-final"
                tooltip={
                    disabledID
                        ? 'User must have assigned portfolio to follow'
                        : 'Click here to Follow'
                }
            />
        </div>

        // Column 9

        const actionHtml = <div className="expand-column">
            <a href={`/ClosingBinders/Portfolio/Requirements?id=${docId}`} title="View details page">
                <div className="expand-column-off">
                    <img src={actionlogo}></img>
                </div>
            </a>
        </div>

        let countData = {}
        if(props.fileCountData){
             countData = props.fileCountData.find((counts) => counts.requirementId === item.id);
        }

        // Column 10
        const countHtml = props.fileCountData ? <div className='data-table-cell'>
            <div>Total Files : { countData?.totalCount ?? 0 }</div>
            {countData?.uploadCount > 0 && <div>Uploaded : {countData?.uploadCount}</div>}
            {countData?.copiedCount > 0 && <div>Copied : {countData?.copiedCount}</div>}
            {countData?.finalizingCount > 0 && <div>Finalizing : {countData?.finalizingCount}</div>}
            {countData?.finalizedCount > 0 && <div>Finalized : {countData?.finalizedCount}</div>}
            {countData?.uploadingCount > 0 && <div>Uploading : {countData?.uploadingCount}</div>}
            {(countData?.reuploadCount > 0 || countData?.failedCount > 0) && <div>Re-Upload : {countData?.reuploadCount + countData?.failedCount}</div>}
        </div> : <USBLoader determinate={false} variant="simple"></USBLoader>

        if (statusValue != "Not Submitted") {
            if(features.length > 0 && features.includes("feature_filecount_visible")){
                return { first: dateHtml, second: parentHtml, third: dealHtml, fourth: docTypeHtml, fifth: closingbinderdateHtml, sixth: receivedDateHtml, seventh: statusHtml, eighth: followHtml, ninth: actionHtml, tenth : countHtml }
            } else {
                return { first: dateHtml, second: parentHtml, third: dealHtml, fourth: docTypeHtml, fifth: closingbinderdateHtml, sixth: receivedDateHtml, seventh: statusHtml, eighth: followHtml, ninth: actionHtml}
            }
        } else {
            if(features.length > 0 && features.includes("feature_filecount_visible")){
                return { first: dateHtml, second: parentHtml, third: dealHtml, fourth: docTypeHtml, fifth: closingbinderdateHtml, sixth: statusHtml, seventh: followHtml, eighth: actionHtml , ninth : countHtml }
            } else {
                return { first: dateHtml, second: parentHtml, third: dealHtml, fourth: docTypeHtml, fifth: closingbinderdateHtml, sixth: statusHtml, seventh: followHtml, eighth: actionHtml }
            }
        }
    };

    return (
        <>
            {statusValue !== 'Not Submitted' && <USBTable
            handlePageChange={(e)=> {
                setTimeout(() => {
                  setCurrentPageTemp(e)
                }, 100);
              }
              }
                currentPageIndex={currentPage}
                addClasses="pagination-container-cb-portfolio-cb-portfolio"
                displayVariant="auto"
                useBorder="true"
                paginationOptions={rangeArray}
                handleItemCountChange={(itemCount) =>
                    updateRangeArray(itemCount)
                }
                paginationLabels={{ ...paginationLabels }}
                paginationPosition="both"
                columnData={tableHeaders}
                tableData={paginationtableData()}
            />
            }
            {statusValue == 'Not Submitted' && <USBTable
            currentPageIndex={currentPage}
            handlePageChange={(e)=> {
                setTimeout(() => {
                  setCurrentPageTemp(e)
                }, 100);
              }
              }
                addClasses="pagination-container-cb-portfolio"
                displayVariant="auto"
                useBorder="true"
                paginationOptions={rangeArray}
                handleItemCountChange={(itemCount) =>
                    updateRangeArray(itemCount)
                }
                paginationLabels={{ ...paginationLabels }}
                paginationPosition="both"
                columnData={notSubmittedTableHeaders}
                tableData={paginationtableData()}
            />
            }
        </>
    )
}

export default PaginatedTable;