import React, { useState, useEffect, useContext, useRef } from 'react';
// import Router from 'next/router'
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
// import Layout from '../../components/Layout/Layout'
import moment from 'moment';
import { endpoints } from '../../../config/connections';
import { DocContext } from '../context/DocContext';
import callApi from '../../components/utilities/callApi';
import Loader from '../../components/utilities/Loader';
import { saveAsFile, helpAndFaq } from '../../components/utilities/Utils';
import './DocumentTemplate.css';
import { useHistory } from 'react-router-dom';
import {USBIconUpload,USBIconDownload,USBIconDelete} from '@usb-shield/react-icons';
import Messages from '../../components/organisms/Messages';
import axios from 'axios';
import { parseJwt } from '../../components/utilities/Utils';
import {  USBButton } from '@usb-shield/components-react';
import { PermissionRoleContext } from '../../components/Hooks/usePermissionRoles';

export const loadList = (setDocuments, setLoading) => {
  setLoading(true);
  const listUrl = `${endpoints.envUrl}/document-management/file-download/v1/documents/DocumentTemplates/files`;
  callApi
    .getApiData(listUrl)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data.filter((item) => {
          return item.category.toLowerCase() !== 'guide';
        });
        setDocuments(data);
      }
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};




export const downloadDocument = (id, setLoading) => {
  setLoading(true);
  const apiURL = `${endpoints.api}/document-management/file-download/v1/files/${id}`;
  callApi
    .getApiData(apiURL)
    .then((result) => {
      const fileName = result.data.fileName.replace(
        'Content-Disposition: attachment; filename=',
        ''
      );
      saveAsFile(result.data.binary, fileName);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

export const firstLetterUpper = (name) => {
  const name1 = name.replace(/-/gi, ' '); 
  const name2 = name1.replace(/_/gi, ' ');
  const name3 = name2.toLowerCase().split(' ');
  name3[0] = name3[0].charAt(0).toUpperCase() + name3[0].slice(1);
  return name3.join(' ');
};


export const downloadFAQ = (setLoading) => {
  helpAndFaq(setLoading);
};

const DocumentTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedTemplateDetails,setSelectedTemplateDetails] = useState({ id : null, category : null, format : null})
  const [successFlag, setSuccessFlag] = useState(false)
  const [failedFlag, setFailedFlag] = useState(false)
  const [messageContent, setMessageContent] = useState('')
  const { setCurrentMenu } = useContext(DocContext);
  const { permissionDetails } = useContext(PermissionRoleContext);
  let history = useHistory();
  const replaceRef = useRef(null);
  const fileInputRef = useRef(null);

  
  useEffect(() => {
    if (!sessionStorage.getItem('tok')) {
      history.push('/');
    } else {
      setCurrentMenu('doctemplates');
      loadList(setDocuments, setLoading);
    }
  }, []);

  const textContentTemplate = (data,category, setLoading) => {
    // FILE TYPE / EXTENTION
    const docName = data?.documentName?.split('.');
    const documentName = firstLetterUpper(docName[0]) || firstLetterUpper(data.documentName);
    const format = docName[1] || data.mimeType
    // UPDATED DATE
    const dateField = data.uploadDate.split('T');
    const temp = dateField[0].replace(/-/gi, '/');
    const uploadDate = moment(temp, 'YYYY/MM/DD').format('MM/DD/YYYY');
    return (
        <div className="txt-content-item">
          <div style={{display:'flex'}}>
          <div style={{width : '95%'}}>
            <button
              type="button"
              data-testid="download-file"
              className="inline-button"
              onClick={() => downloadDocument(data.filenetId, setLoading)}
            >
              &nbsp;
              {documentName.charAt(0).toUpperCase() + documentName.slice(1)}
            </button>
            &nbsp;
            <span className="extname">
              &#40;
              {data.mimeType}
              &#41;
            </span>
            &nbsp;&nbsp;&nbsp;
            <span className="updatedOn">
              Updated &nbsp;
              {uploadDate}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
          <div style={{width : '5%'}}>
          <button type='button' className='replace-button' 
               onClick={() => downloadDocument(data.filenetId, setLoading)}>
          <USBIconDownload ></USBIconDownload>
          </button>
        </div>
        
        { ['tools'].some((item) => permissionDetails?.includes(item)) ? (<>
          <div style={{width : '5%'}}>
          <button type='button' className='replace-button' onClick={()=>{
            setSelectedTemplateDetails({
              id : data.filenetId,
              category : category,
              format : format
            })
            replaceRef?.current.click()
            }}>
          <USBIconUpload ></USBIconUpload>
          </button>
        </div>

       

       

        <div style={{width : '5%'}}>
          <button type='button' className='replace-button' 
               onClick={() => deleteDocument(data.filenetId, setLoading,setDocuments)}>
          <USBIconDelete ></USBIconDelete>
          </button>
        </div>
        </>) : null}
         
        </div>
      </div>
        
    );
  };


    const deleteDocument = (id, setLoading, setDocuments) => {

  const isConfirmed = window.confirm('Are you sure you want to delete this document?');

  if (isConfirmed) {
    setLoading(true);
    const apiURL = `${endpoints.api}/document-management/file-upload/v1/delete/files/${id}`;
    callApi
      .deleteApi(apiURL)
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          setMessageContent('Successfully deleted the template file');
          setFailedFlag(false);
          setSuccessFlag(true);
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          // Optionally, update the documents list
          // setDocuments((prevDocuments) => prevDocuments.filter(doc => doc.id !== id));
          setTimeout(() => {
            setFailedFlag(false);
            setSuccessFlag(false);
          }, 5000);
          loadList(setDocuments, setLoading);
        } else {
          setMessageContent('Failed to delete the template file');
          setFailedFlag(true);
          setSuccessFlag(false);

          setTimeout(() => {
            setFailedFlag(false);
            setSuccessFlag(false);
          }, 5000);
        }
      })
      .catch((error) => {
        setMessageContent('An error occurred while deleting the template file');
        setFailedFlag(true);
        setSuccessFlag(false);
        setTimeout(() => {
          setFailedFlag(false);
          setSuccessFlag(false);
        }, 5000);
      })
      .finally(() => {
        setLoading(false);
      });
  }
};
  

  const replaceDocumentTemplate = (e) => {
     if(selectedTemplateDetails.format.toLowerCase() !== e.target.value.split('.').pop().toLowerCase()){
      setLoading(true)
      setMessageContent('The new template should be of the same format as the current one.')
      setFailedFlag(true);
      setSuccessFlag(false)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setTimeout(() => {
        setFailedFlag(false);
        setSuccessFlag(false);
      }, 5000);
      if (replaceRef.current) {
        replaceRef.current.value = null; // Clear the file input using ref
      }
      setLoading(false)
    } else {
      setLoading(true)
      const url = `${endpoints.api}/document-management/file-upload/v1/users/userId/documents/DocumentTemplates/files/documentTemplateReplace?fileNetId=${selectedTemplateDetails.id}&category=${selectedTemplateDetails.category}`;
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      return axios.post(url, formData, {
        headers: {
          'content-Type' : 'multipart/form-data',
          'Accept' : 'application/json',
          'Correlation-ID' : `${sessionStorage.getItem('loggedUser')} ${sessionStorage.getItem('correlationId')}`,
          'Role-ID' : `${sessionStorage.getItem('userrole')}`,
          'Client-Application': 'CDC',
          'user-id':`${sessionStorage.getItem('remote_user') ? JSON.parse(sessionStorage.getItem('remote_user')).userID : parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
          'Authorization' : `Bearer ${sessionStorage.getItem('at')}`
        }
      })
      .then((response) => {
        setLoading(false)
        setMessageContent('Successfully replaced document template.')
        setSuccessFlag(true);
        setFailedFlag(false)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setTimeout(() => {
          setFailedFlag(false);
          setSuccessFlag(false);
        }, 5000);
        loadList(setDocuments, setLoading);
      })
      .catch(() => {
        setLoading(false)
        setMessageContent('There is some issue while replacing document template.')
        setFailedFlag(true);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setTimeout(() => {
          setFailedFlag(false);
          setSuccessFlag(false);
        }, 5000);
      })
    }
  }

  const closeMessage = () => {
    setFailedFlag(false);
    setSuccessFlag(false);
};

const [selectedCategory, setSelectedCategory] = useState('');
const [isOtherCategory, setIsOtherCategory] = useState(false);
const [newCategory, setNewCategory] = useState('');
const [file, setFile] = useState(null);

const [categories, setCategories] = useState(['Select']);


useEffect(() => {
  const uniqueCategories = ['Select', ...new Set(documents.map((item) => item.category)),'Others'];
  setCategories(uniqueCategories);
}, [documents]);


const handleCategoryChange = (e) => {
  const value = e.target.value;
  setSelectedCategory(value);
  setIsOtherCategory(value === 'Others');
};

const allowedFileTypes = ['.pdf', '.xls', '.xlsx', '.doc', '.docx', '.jpeg', '.jpg', '.xml', '.xlsm'];

const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  if (selectedFile) {
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      alert('Invalid file type. Please select a valid file.');
      e.target.value = null; // Clear the file input
      return;
    }
    setFile(selectedFile);
  }
};

const handleSubmit = (e) => {
  e.preventDefault();

  if (selectedCategory === 'Select') {
    alert('Please select a category.');
    return;
  }

  const category = isOtherCategory ? newCategory : selectedCategory;

  if (isOtherCategory && !newCategory) {
    alert('Please enter a new category.');
    return;
  }
  
  if (!file) {
    alert('Please select a file to upload.');
    return;
  }

  if (isOtherCategory && newCategory=="Guide" && file?.name?.split('.')?.pop()?.toLowerCase() !== 'pdf') {
    alert('You can upload only .pdf in Guide documents.');
    return;
  }

  const formData = new FormData();
  formData.append('file', file);

  const apiURL = `${endpoints.api}/document-management/file-upload/v1/users/userId/documents/DocumentTemplates/files/documentTemplateReplace?fileNetId=New&category=${category}`;
   
  setLoading(true)

  return axios.post(apiURL, formData, {
    headers: {
      'content-Type' : 'multipart/form-data',
      'Accept' : 'application/json',
      'Correlation-ID' : `${sessionStorage.getItem('loggedUser')} ${sessionStorage.getItem('correlationId')}`,
      'Role-ID' : `${sessionStorage.getItem('userrole')}`,
      'Client-Application': 'CDC',
      'user-id':`${sessionStorage.getItem('remote_user') ? JSON.parse(sessionStorage.getItem('remote_user')).userID : parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
      'Authorization' : `Bearer ${sessionStorage.getItem('at')}`
    }
  })
  .then((response) => {
   
    setMessageContent('Successfully uploaded document template.')
    setSuccessFlag(true);
    setFailedFlag(false)
    setSelectedCategory('Select');
    setIsOtherCategory(false);
    setNewCategory('');
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input using ref
    }
  
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setLoading(false);
    setTimeout(() => {
      setFailedFlag(false);
      setSuccessFlag(false);
    }, 5000);
    loadList(setDocuments, setLoading);
  })
  .catch(() => {
    setLoading(false)
    setMessageContent('There is some issue while uploading document template.')
    setFailedFlag(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setFailedFlag(false);
      setSuccessFlag(false);
    }, 5000);
  })
}

 

  return (
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="documenttemplate"
            text="Home"
            breadcrumbs={[
              // {
              //   text: 'My portfolio',
              //   href: '/ReportingDashboard',
              // },
              {
                text: 'Document templates',
                href: '',
              },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8} />
      </USBGrid>
      <USBGrid addClasses="content-frame doctemplate-page">
        <USBColumn largeSpan={16}>
          {loading ? <Loader /> : null}
          {successFlag || failedFlag ? (
                <div
                    className="message-content"
                    data-testid="messageContent"
                    role="alert"
                >
                    <Messages
                        success={successFlag}
                        closeMessage={closeMessage}
                        singleUploadFail={failedFlag}
                        messageContent={messageContent}
                        setFailedFlag={setFailedFlag}
                        setSuccessFlag={setSuccessFlag}
                        severity={'high'}
                    />
                </div>
            ) : (
                ''
            )}
          <div className="doctemplate-head">
            <div className="doc-template-head">
              <h1 className="heading-h1">Document templates</h1>
              <div className="doc-content">
                Get a jump start on your reporting requirements with our
                document templates.
                <br />
                You’ll find templates organized by reporting requirement type.
                Select a document to download it.
                <br />
                { ['tools'].some((item) => permissionDetails?.includes(item)) ? (<> To replace Guide and FAQ use category as "Guide".  Only PDF doc format is allowed for this category.
                  </>) : null}
               
              
              </div>
            </div>
            <div className="report-download doc-template-download">
              <div className="report-download-title">ADDITIONAL DOCUMENTS</div>
              <button
                type="button"
                className="download-link"
                onClick={() => downloadFAQ(setLoading)}
              >
                Guide &amp; FAQs
              </button>
              <span className="report-download-block">(PDF)</span>
            </div>
          </div>

   
          { ['tools'].some((item) => permissionDetails?.includes(item)) ? (<>
            <div className="report-download doc-template-upload">
            <div className="report-download-title">UPLOAD DOCUMENTS (Allowed types are .pdf, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .xml and .xlsm)</div>

      <form  className="upload-form">
    
      <div>
        <label htmlFor="category">Category:</label>
        <select id="category" value={selectedCategory} onChange={handleCategoryChange}>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      {isOtherCategory && (
        <div>
          <label htmlFor="newCategory">New Category:</label>
          <input
            type="text"
            id="newCategory"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </div>

        
      )}
    

    <div>
        <label htmlFor="file">File:</label>
        <input type="file" id="file" ref={fileInputRef} onChange={handleFileChange} />
        
      </div>

            <USBButton variant="primary" handleClick={(e)=>handleSubmit(e)}>
              Upload
            </USBButton>
    </form>
         
          </div>
          </>) : null}
            
         
          <input type='file' ref={replaceRef} hidden={true} onChange={(e)=>{
            replaceDocumentTemplate(e)
          }}/>
          { documents?.sort((a, b) => a.category.localeCompare(b.category)).map((item, index) => {
              const categoryName = firstLetterUpper(item.category);
              return (
                <div key={index}>
                  <h3 className="doctype-subhead">{categoryName}</h3>
                  <div className="txt-content">
                    {item.documentMetadata &&
                      item.documentMetadata.map((data) => {
                        return textContentTemplate(data,item.category, setLoading);
                      })}
                  </div>
                </div>
              );
            })}
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default DocumentTemplate;
