import React, { useEffect, useState, useMemo,useContext } from 'react';
// @ts-ignore
import { USBLink } from '@usb-shield/components-react';
import Loader from '../../components/utilities/Loader';
import moment from 'moment';
import { getInboxRequirements, getDefaultFilters } from './utils';
import InboxFilters from './InboxFilters';
import InboxTable from './InboxTable';
import ReadUserList from './ReadUserList';
import { USBIconDownload, USBIconPeople } from '@usb-shield/react-icons';
import './inbox.scss';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import actionlogo from '../../assets/images/rightArrow.png';
import { FeatureFlagsContext } from '../../components/Hooks/useFeatureFlags';

function Inbox() {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState({ loading: true, error: null });
  const [requirements, setRequirements] = useState(null);
  const [filters, setFilters] = useState(getDefaultFilters());
  const [opneModel, setOpenModel] = useState(false)
  const [requirementID, setRequirementId] = useState('')
  const { featureDetails } = useContext(FeatureFlagsContext);
  async function fetchData() {
    await getInboxRequirements({ setRequirements, setStatus });
    await setStatus({ loading: false, error: status.error });
  }

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if(featureDetails?.length>0){
    if(!featureDetails?.includes("feature_inbox_visible")){
       window.location.href="/Redirect";
    }
}
}, [featureDetails])
  const filteredRequirements = useMemo(() => {
    console.log(filters);
    console.log(requirements);
    return requirements?.filter((item) =>
      Object.entries(filters).every(([key, obj]) => {
        const keys = key.split('.');
      return (
                    keys.reduce((acc, current) => acc && acc[current], item)?.substring(0, obj?.value?.length) === obj?.value || keys.reduce((acc, current) => acc && acc[current], item)?.substring(0, obj?.value?.length) === moment(obj?.value).format('MM/DD/YYYY')
        );
      })
    );
  }, [filters, requirements]);

  const columns = [
    {
      label: 'Received Date',
      accessor: 'asOfDate',
      sortable: true,

      cell: (row) => {
        const date = new Date(row.asOfDate);
        return <>{date.toLocaleString("en-US", {
          year: "numeric",
        month: "2-digit",
        day: "2-digit"
      })}</>;
      },
      style: { width: '15%' },
    },
    {
      label: 'Deal Name',
      accessor: 'metadata.dealName',
      sortable: true,
      style: { width: '20%' },
    },
    {
      label: 'Legal Entity',
      accessor: 'metadata.reportingEntity',
      sortable: true,
      style: { width: '20%' },
    },
    {
      label: 'Document Type',
      accessor: 'category',
      style: { width: '15%' },
      cell: (row) => {
        return <>{ row.category.charAt(0).toUpperCase() + row.category.slice(1)}</>;
      },
    },
    {
      label: 'Status',
      accessor: 'status',
      sortable: true,
      style: { width: '10%' },
      
    },
    
    ... sessionStorage.getItem('userrole') === 'employee' ? [{
      label: 'Read By',
      accessor: 'read',
      style: { width: '10%' },
      cell: (row)=>{
        function  UserBoxRender(params) {
          setOpenModel(true)
          setRequirementId(row.id)
        }
        const disabledButton = row.status === 'New' ? true : false;
        return (
          <>
          <button
            className="table-action-icon"
            onClick={() => {
              UserBoxRender()
            }}
            disabled={disabledButton}
          >
            <USBIconPeople
              colorVariant={disabledButton ? 'grey60' : 'brand-primary'}
              title={`Click`}
              ariaHidden={true}
              id="add-icon"
              focusable={true}
              tabindex={-1}
              size={24}
              addClasses="action-preview-icon"
            />
          </button>
          </>
        )
      }
    }] : []
    ,



    {
      label: 'Action',
      accessor: '',
      cell: (row) => {
        return (
          <>
            <USBLink
              href={`/Inbox/${row.id}?status=${row.status}`}
              linkType="basic"
            >
               <div className="expand-column-off">
                <img src={actionlogo}></img>
              </div>
            </USBLink>
          </>
        );
      },
      style: { width: '10%' },
    },
  ];
  function onCancel(){
    setOpenModel(false)
}
  return (
    <section className="inbox-portfolio-container width-100">

{featureDetails?.length>0 && featureDetails?.includes("feature_inbox_visible")?

      <section className="full-height">

          {sessionStorage.getItem('remote_user') ? (
            <div className="warning-panel-remote">
              You are impersonating the user:{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).firstName}{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).lastName}. To
              exit, click the 'Exit User' link above.
            </div>
          ) : (
            ''
          )}
       

      <USBBreadcrumb
            id="inbox"
            text="Inbox"
            breadcrumbs={[
              {
                id: 1,
                text: 'Inbox',
                href: '/Inbox',
              },
            ]}
          />


        <h1 className="heading-h1 p-t-md">Inbox</h1>
        <p className="secondary-text">
          You'll see documents for your customer listed below. Use the filters
          to view documents for a specific organization. Select a document to
          view or download files.
        </p>
         {/* component for popup on screen  */}
        <ReadUserList
            open={opneModel}
            id = {requirementID}
            toggle={onCancel}
          />
        <InboxFilters
          requirements={requirements}
          filters={filters}
          setFilters={setFilters}
        ></InboxFilters>
        {/* @ts-ignore */}
        <InboxTable
          data={filteredRequirements || []}
          headers={columns}
          status={status}
          setStatus={setStatus}
          searchProperties={{
            enable: true,
            keys: [
              'asOfDate',
              'metadata.dealName',
              'metadata.reportingEntity',
              'category',
              'status',
            ],
          }}
        ></InboxTable>
        {/* page loader */}
        {/* @ts-ignore */}
        {status.loading ? <Loader /> : null}
      </section>
      :''}
    </section>
  );
}

export default Inbox;
