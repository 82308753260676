import React, { useEffect, useState, useMemo,useContext } from 'react';
import Spinner from '../../components/shared/Spinner';
import {
  getInboxMetadata,
  getInboxFiles,
  getValueByNestedKey,
  updateInboxItemStatus,
  isEmployee
} from './utils';
import InboxTable from './InboxTable';
import DownloadMultiple from './DownloadMultiple';
import { metadataDetails, columns } from './inboxConfig';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import { FeatureFlagsContext } from '../../components/Hooks/useFeatureFlags';
export default function InboxDetails() {
  const { featureDetails } = useContext(FeatureFlagsContext);
  const [inboxMetadata, setInboxMetadata] = useState({});
  const [status, setStatus] = useState({ loading: true, error: null });
  const [files, setFiles] = useState([]);

  async function fetchData() {
    await getInboxMetadata({ setInboxMetadata, setStatus });
    await getInboxFiles({ setFiles, setStatus });
    await setStatus({ loading: false, error: status.error });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!isEmployee()) {
        updateInboxItemStatus();
    }
  }, [inboxMetadata]);

  useEffect(() => {
    if(featureDetails?.length>0){
    if(!featureDetails?.includes("feature_inbox_visible")){
       window.location.href="/Redirect";
    }
}
}, [featureDetails])

  const totalNumberOfFiles = files?.length || 0;

  return (
<>
    {featureDetails?.length>0 && featureDetails?.includes("feature_inbox_visible")?
    <section className="inbox-details-container">
      {status.loading ? <Spinner /> : null}

      <USBBreadcrumb
            id="inbox"
            text="Inbox"
            breadcrumbs={[
              {
                id: 1,
                text: 'Inbox',
                href: '/Inbox',
              },
              {
                id: 2,
                text: 'Inbox Details'
              },
            ]}
          />

      <h1 className="heading-h1 p-t-md">Inbox Details</h1>
      <p className="secondary-text p-t-xs p-b-md">
        You'll see files and details related to the selected inbox document.
        Select a file to view or download.
      </p>
      <section className="inbox-metadata-container m-t-lg m-b-lg">
        {metadataDetails.map((metadataItem) => (
          <section className="metadata-item" key={metadataItem.key}>
            <p className="metadata-title"> {metadataItem.title}</p>
            <p className="metadata-value">
              {getValueByNestedKey(inboxMetadata, metadataItem.key)}
            </p>
          </section>
        ))}
      </section>
      <p className="p-t-lg">
        Total files for this document: {totalNumberOfFiles}
      </p>

      {/* <section className="m-t-lg"> */}
        <InboxTable
          data={files || []}
          headers={columns}
          status={status}
          setStatus={setStatus}
          searchProperties={{
            enable: true,
            keys: [
              'documentMetadata.documentName',
              'documentMetadata.uploadDate',
            ],
          }}
          tableActions={{
            enable: true,
            selectionKey: 'documentMetadata',
            Component: DownloadMultiple,
          }}
        ></InboxTable>
      {/* </section> */}
    </section>
    :""}
    </>
  );
}
